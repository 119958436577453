import axios from 'axios';

export default async function requestHandler({
  method,
  url,
  headers,
  data,
  isMultipart,
}) {
  return new Promise((resolve, reject) => {
    // Context
    const userToken = localStorage.getItem('iseTutorAccessToken');

    axios({
      method,
      url,
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': !isMultipart
          ? 'application/json'
          : 'multipart/form-data',
        ...headers,
      },
      data,
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}

export async function requestHandler2({
  method,
  url,
  headers,
  data,
  isMultipart,
  setState,
  successFunction,
  errorFunction,
  load,
}) {
  const userToken = localStorage.getItem('iseTutorAccessToken');
  if ((setState && load === true) || (setState && load === undefined)) {
    setState({ data: null, error: null, isLoading: true });
  } else if (setState && load === false) {
    setState({ data: null, error: null, isLoading: false });
  }
  axios({
    method,
    url,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': !isMultipart ? 'application/json' : 'multipart/form-data',
      ...headers,
    },
    data,
  })
    .then((res) => {
      if (setState) {
        setState({
          isLoading: false,
          data: res?.data,
          error: null,
        });
      }
      if (successFunction) {
        successFunction();
      }

      setTimeout(() => {
        setState({ data: null, error: null, isLoading: false });
      }, 7000);
    })
    .catch((err) => {
      console.log(err, 'Error');
      if (setState) {
        setState({
          isLoading: false,
          data: null,
          error: err?.response?.data
            ? JSON.stringify(err?.response?.data)
            : err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage?.toString()
            : err.message,
        });
      }
      if (errorFunction) {
        errorFunction();
      }
    });
}
