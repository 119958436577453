import { useState, useEffect } from 'react';
import Switch from 'react-js-switch';

const ManageCookiesPreference = ({
  isOpen,
  onClose,
  handleSavePreferences,
}) => {
  const [preferences, setPreferences] = useState({
    strictlyNecessary: true,
    performance: false,
    marketing: false,
  });

  const [expandedSections, setExpandedSections] = useState({
    strictlyNecessary: true,
    performance: false,
    marketing: false,
  });

  const sections = [
    {
      key: 'strictlyNecessary',
      title: 'Strictly Necessary Cookies',
      description:
        'These cookies are needed to run our website, to keep it secure, and to obey regulations that apply to us. These may include cookies that remember one’s region or country, language, accessibility options and your settings.',
      isDisabled: true,
    },
    {
      key: 'performance',
      title: 'Performance Cookies',
      description:
        'These cookies tell us how users use our website. We study and profile this data to help us improve our products and performance.',
    },
    {
      key: 'marketing',
      title: 'Marketing Cookies',
      description:
        'These cookies help us decide which products, services, and offers may be relevant for you. We may use this data to tailor the ads you see on our own and other websites and apps.',
    },
  ];

  useEffect(() => {
    const savedPreferences = JSON.parse(
      localStorage.getItem('cookiePreferences')
    );
    if (savedPreferences) {
      setPreferences(savedPreferences);
    }
  }, []);

  const toggleSection = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const togglePreference = (preference) => {
    setPreferences((prevState) => ({
      ...prevState,
      [preference]: !prevState[preference],
    }));
  };

  const savePreferences = () => {
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
    handleSavePreferences();
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center px-4">
      <div className="pt-4 pb-1 font-sec bg-white w-full max-w-3xl max-h-[80vh] rounded-lg shadow-lg">
        <div
          className="overflow-y-scroll space-y-4 p-4"
          style={{ maxHeight: '65vh', margin: '0 12px' }}
        >
          <div className="flex flex-col gap-3">
            <h3 className="text-xl font-bold mb-4">Your Consent Preferences</h3>
            <p>
              Some cookies are necessary to make our website work and cannot be
              turned off. We however need your consent to store cookies on your
              terminal equipment and collect information from your terminal
              equipment for the purposes of improving our services.
            </p>
            <p>
              You can make your choices below and update them at any time by
              clicking on the 'Cookies' button.
            </p>
            <div className="flex justify-between w-[65%] sm:w-[40%] md:w-[32%] lg:w-[35%] base:w-[60%] ml-auto mt-4">
              <button
                className="border border-[#dcdcdc] px-4 py-1 font-medium rounded-lg"
                onClick={() => {
                  setPreferences({
                    strictlyNecessary: true,
                    performance: false,
                    marketing: false,
                  });
                }}
              >
                Reject all
              </button>
              <button
                className="border border-[#dcdcdc] px-4 py-1 font-medium rounded-lg"
                onClick={() => {
                  setPreferences({
                    strictlyNecessary: true,
                    performance: true,
                    marketing: true,
                  });
                }}
              >
                Accept all
              </button>
            </div>
          </div>

          <div className="flex flex-col">
            {sections.map((section, index) => (
              <div
                key={section.key}
                className={`p-4 ${
                  index === 0
                    ? 'border-t border-t-[#dcdcdc] border-b border-b-[#bfbfbf]'
                    : 'border-b'
                } border-[#bfbfbf]`}
              >
                <div className="flex justify-between items-center w-full">
                  <div className="flex items-center space-x-2">
                    <button
                      className="text-2xl font-bold"
                      onClick={() => toggleSection(section.key)}
                    >
                      {expandedSections[section.key] ? '-' : '+'}
                    </button>
                    <h4>{section.title}</h4>
                  </div>
                  <Switch
                    value={preferences[section.key]}
                    onChange={() => togglePreference(section.key)}
                    backgroundColor={{ on: '#037ef3', off: '#bfbfbf' }}
                    borderColor={{ on: '#fff', off: '#fff' }}
                    disabled={section.isDisabled}
                  />
                </div>
                <p
                  className={`mt-2 transition-all duration-500 ease-in-out overflow-hidden ${
                    expandedSections[section.key]
                      ? 'max-h-[200px] opacity-100'
                      : 'max-h-0 opacity-0'
                  }`}
                >
                  {section.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full border-t border-[#bfbfbf]">
          <div className="flex justify-end p-4">
            <button
              onClick={savePreferences}
              className="rounded-lg px-4 py-2 bg-[#037ef3] text-white"
            >
              Save and Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageCookiesPreference;
