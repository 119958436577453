import Layout from '../../Components/Layout/Layout';
import GmwHomePageHero from '../../Containers/GmwHomePageHero/GmwHomePageHero';
import GmwAboutPage from '../../Containers/GmwAboutPage/GmwAboutPage';
import GmwShowCasePage from '../../Containers/GmwShowCasePage/GmwShowCasePage';

const GmwHome = () => {
  return (
    <Layout>
      <GmwHomePageHero />
      <GmwAboutPage />
      <GmwShowCasePage />
    </Layout>
  );
};

export default GmwHome;
