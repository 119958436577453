import Layout from "../../Components/Layout/Layout";
import ForOrganizationInternationalPool from "../../Containers/ForOrganizationInternationalPool/ForOrganizationInternationalPool";
import ForOrganizationsCreateOppurtunities from "../../Containers/ForOrganizationsCreateOppurtunities/ForOrganizationsCreateOppurtunities";
import ForOrganizationsHeroSection from "../../Containers/ForOrganizationsHeroSection/ForOrganizationsHeroSection";
// import ForOrganizationsTestimonials from "../../Containers/ForOrganizationsTestimonials/ForOrganizationsTestimonials";
import ForOurganizationsOurPartners from "../../Containers/ForOurganizationsOurPartners/ForOurganizationsOurPartners";
import LandingPageNewsLetterSignUp from "../../Containers/LandingPageNewsLetterSignUp/LandingPageNewsLetterSignUp";

const ForOrganizations = () => {
  return (
    <Layout>
      <ForOrganizationsHeroSection />
      <ForOrganizationsCreateOppurtunities />
      <ForOurganizationsOurPartners />
      {/* <ForOrganizationsTestimonials /> */}
      <ForOrganizationInternationalPool />
      <LandingPageNewsLetterSignUp />
    </Layout>
  );
};

export default ForOrganizations;
