import Layout from "../../Components/Layout/Layout";
import LeadershipRegisterBanner from "../../Containers/LeadershipRegisterBanner/LeadershipRegisterBanner"

const LeadershipSummitRegisterHome= () => {
    return (
        <Layout>
            <LeadershipRegisterBanner />
        </Layout>
    );
};

export default LeadershipSummitRegisterHome;