import classes from "./LocalCommitteeOurGallery.module.css";

const LocalCommitteeOurGallery = ({lcPhotos}) => {
  const lcImages = lcPhotos?.gallery;
  
  return (
    <section className={classes.container}>
      <h4>OUR GALLERY</h4>

      {
        lcImages && (
          <div className={classes.imagSection}>
        {lcImages.slice(0, 7).map((data, i) => {
          return (
            <div className={classes.image} key={i}>
              <img src={data} alt="LC" loading="lazy" />
              {(i === 6) &&  (lcImages.length - 7 > 0) ? (
                <div className={classes.viewMore}>
                  + {lcImages.length - 7} more
                </div>
              ): ''}
            </div>
          );
        })}
      </div>
        )
      }
    </section>
  );
};

export default LocalCommitteeOurGallery;
