import React from 'react'
import classes from "./BlogArticleThree.module.css";
import SuperHero1 from "../../Assets/Images/superhero1.webp";
import SuperHero2 from "../../Assets/Images/superhero2.webp";
import SuperHero3 from "../../Assets/Images/superhero3.webp";
import BlogHeader2 from "../../Assets/Images/BlogHeader2.webp";
import BlogHeader1 from "../../Assets/Images/BlogHeader1.webp";
import FeaturedBlogHeader1 from "../../Assets/Images/FeaturedBlogHeader1.webp";
import Author from '../../Assets/Images/author1.jpg';
import ShareButton from '../../Components/ShareButton/ShareButton';
import { Link } from 'react-router-dom';
import { scrollToTheTop } from "../../Utilities/scrollToTop";

function BlogArticleThree() {
  return (
    <section className={classes.container}>
      <div className={classes.blogHeading}>
        <div className={classes.blogHeadingTitle}>
          <span>17th March, 2023</span>
          <h1>The Superheroes Among Us</h1>

          <div className={classes.blogHeadingTitleAuthor}>
            <img src={Author} alt="" />
            <span>By Emmanuel Obi</span>
          </div>

        </div>

        <div className={classes.blogHeadingImg}>
          <img src={BlogHeader2} alt="The Superheroes Among Us" />
        </div>
      </div>
      <div className={classes.blogContent}>

        <p>When you hear the word “superhero”, what comes to mind? I bet it’s the picture of a man in a blue and red costume with chiselled abs, a cape and an S on the chest. It could even be a woman in an armour-like costume, wicked boots and a rope by her waist.</p>
        <p>All superheroes have one thing in common. They arrive just in time to save the day. They are humanity’s last line of defence in the face of crisis, and they live a life of servitude in the knowledge that without them, we would all be in grave danger. They often say profound statements like, “with great power comes great responsibility”, which is why many of them go about their duties without demanding any form of payment — just gratitude and vibes.</p>
        <p>Unfortunately, these kinds of superheroes only exist in the cinematic universes of DC and Marvel. This isn’t to say superheroes don’t exist, though. They do, and I had the privilege to speak to some of them over the past week.</p>
        <p>These individuals may not have cool superpowers or flashy costumes, but they possess something greater. They possess the willingness to make an impact in the communities around them and positively impact the lives of others.

        </p>
        <p>It wasn’t easy convincing them to allow me to reveal their secret identities, but in the end, I succeeded. Meet three real-life superheroes living among us.</p>

        <h3>John Esho, aka Superman</h3>
        <img src={SuperHero1} alt="John Esho, aka Superman" />

        <p>He probably does not remember, but I first met John in April last year at a conference in Ife, Osun state. He wore a checkered t-shirt and looked like the popular American rapper Eazy E.</p>
        <p>He didn’t speak much, and now that I think about it, I don’t think I’ve ever heard the guy say up to five words. This mysterious aura around John reminds me very much of the man of steel, Clark Kent.</p>
        <p>Over a conversation on Whatsapp, John told me about his National Volunteer experience at the iKapture Centre for Development, Calabar, Cross River State, where he worked on a project called Edutech.</p>
        <p>He taught young kids basic computer skills and provided feedback and support. When I asked why he chose to volunteer at Cross River state, considering how far it is from his home in Lagos, he said it was because he wanted to impact and help a community in need.</p>
        <p>He also added that the Edutech project offered him the opportunity to teach computer skills to children, something he is passionate about.        </p>
        <p>The experience moulded him into a more adaptable teacher, a better communicator and a person more open to collaboration. In true superhero fashion, John ended the conversation with a profound quote.</p>
        <p>“Go into your volunteer experience with an open mind and a willingness to learn. Be prepared to adapt to different situations and challenges, and approach every interaction with empathy and understanding. Take the time to connect with the people you are working with, and be open to forming meaningful relationships and connections. Finally, remember that even small contributions can make a big impact.”</p>
        <h3>Odo Oqua, aka Captain Marvel</h3>
        <img src={SuperHero2} alt="Odo Oqua, aka Captain Marvel" />
        <p>Odo first entered my consciousness last year on a WhatsApp group chat when she addressed some misconceptions about the tribes and languages spoken in Cross River state. When I texted her to chat about her National Volunteer experience, her response was swift and cherry, and she immediately struck me as very friendly and cheerful.</p>
        <p>Like John, Odo also left the comfort of her home to volunteer someplace far. She worked on a project called Slum Stars in Makoko, Lagos state, and taught Social studies and English language at a nursery school that needed teachers for those subjects.</p>
        <p>Odo described her National Volunteer experience as a vacation where you use your chill time to give back to society, have fun, gain work experience, face your fears and become a global citizen.</p>
        <p> The most interesting thing about Odo is her resilience. When she discovered that she could not work on her first choice project because the timeline had expired, she did not back down but instead challenged herself and faced her fear of teaching.</p>
        <p>It ended up being a lovely experience that she says she would do all over again if given a chance. Odo’s advice to upcoming volunteers is this. “You can do whatever you set your mind to”.</p>
        <h3>Bright Uzosike, aka Iron Man</h3>
        <img src={SuperHero3} alt="Bright Uzosike, aka Iron" />
        <p>My conversation with Bright was probably the shortest among these three superheroes. He gave off extreme philanthropist vibes, immediately reminding me of Marvel’s billionaire playboy, Tony Stark.</p>
        <p>Bright’s determination to experience unfamiliar cultures and a different environment led him to volunteer at Government Secondary School, Akim, Calabar, Cross River State, where he worked on a project called Reach to Teach. There, he equipped junior and senior students on course to take the popular WAEC exam with computer science knowledge and relevant technological skills.</p>
        <p>Bright said that this experience made him realize that you do not require much to make an impact in your society. According to him, the little you have is enough, and it could even be the catalyst that spurs others into action.</p>
        <p>To round off our conversation, he added this. “Aspiring volunteers should keep an open mind and be humble. You get to see the world in a new light when you think about other people first every day. Truly, it changes your worldview”.</p>
        <p>Like <strong>John, Odo and Bright,</strong> we all have what it takes to become a superhero and positively impact the communities around us. Don’t waste any more time. <a href="https://sites.google.com/aiesec.net/nationalvolunteer/" target="_blank" rel="noopener noreferrer"> Sign up for National Volunteer today</a>.

        </p>
        <div className={classes.shareBlogContent} >

          <span>Share this post</span>
          <ShareButton text="Check out this amazing article! &quot;Dear Amazing Earth Hero&quot;" linktext="http://aiesec.ng/blog/2" />
        </div>
      </div>
      <div className={classes.blogsRelated}>
        <h4>More Articles</h4>
        <div className={classes.blogsRelatedContainer}>
          <Link onClick={scrollToTheTop} className={classes.featuredBlogLinkContainer} to="/blog/1">
            <div className={classes.blogCard}>
              <div className={classes.blogCardImgContainer}>
                <img className={classes.featuredBlogImg} src={FeaturedBlogHeader1} alt="Featured Blog Header" />
              </div>
              <div className={classes.blogCardContainer}>
                <h4 className={classes.blogCardTitle}>I Want To Change The World. How About You?</h4>
                <p className={classes.featuredBlogDesc}>I don't think there has ever been a prouder time to be a young Nigerian living in Nigeria than now, and no, I'm not being sarcastic. For as long as I can remember, the proverb “Youths are the leaders of tomorrow” has been met with jeers and laughter whenever it is said out loud.</p>
                <Link onClick={scrollToTheTop} className={classes.featuredBlogLink} to="/blog/1">
                  <span>Read More  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      opacity="0.7"
                      d="M1.5 10.5L11 1M11 1H4.18868M11 1V7.63208"
                      stroke="#037EF3"
                      stroke-width="1.43396"
                    />
                  </svg>
                </Link>
              </div>
            </div>

          </Link>
          <Link onClick={scrollToTheTop} className={classes.featuredBlogLinkContainer} to="/blog/3">

            <div className={classes.blogCard}>
              <div className={classes.blogCardImgContainer}>
                <img className={classes.featuredBlogImg} src={BlogHeader1} alt="Featured Blog Header" />
              </div>
              <div className={classes.blogCardContainer}>

                <h4 className={classes.blogCardTitle}>Dear Amazing Earth Hero</h4>
                <p className={classes.featuredBlogDesc}>In the wide web of human experiences, “<em>firsts</em>” have always held a special allure — a beautiful blend of excitement and trepidation, a certain kind of anticipation and uncertainty.</p>
                <Link onClick={scrollToTheTop} className={classes.featuredBlogLink} to="/blog/2">
                  <span>Read More  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      opacity="0.7"
                      d="M1.5 10.5L11 1M11 1H4.18868M11 1V7.63208"
                      stroke="#037EF3"
                      stroke-width="1.43396"
                    />
                  </svg>
                </Link>
              </div>

            </div>

          </Link>
        </div>
      </div>
    </section>
  )
}

export default BlogArticleThree