import sanityClient from "../sanityClient";

export const getLocalCommittePage = async (localCommittee) => {
    const query = 
    `*[_type == "homePage" && lower(title) == $title][0]{
    title,
    "lcLogoUrl": image.asset->url,
    altText,
    aboutTextOne,
    aboutTextTwo,
    contact{
      email,
      whatsapp
    },
    "projects": projects[]->{
      title,
      description,
      "projectImageUrl": image.asset->url,
      tags,
      projectLink
    },
    "executiveBoard": executiveBoard[]->{
      name,
      position,
      "profileImageUrl": image.asset->url
    },
    "gallery": gallery[].asset->url,
    newsletter,
    footerText,
    footerContact
  }`
  const params = { title: localCommittee.toLowerCase() }
    const data = await sanityClient.fetch(query,params);
    
    return data;
    }