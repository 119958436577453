import classes from "./Footer.module.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={classes.container}>
      <div className={classes.topSection}>
        <div>
          <p>
            6th floor Co Creation Hub, 294 Herbert Macaulay Way, <br />
            Sabo Yaba, Lagos
          </p>
          <a href="mailto:nigeria@aiesec.net">Nigeria@aiesec.net</a>
          <a href="tel:+2349076349118">(+234) 907 6349 118</a>
        </div>
        <div>
          <h4>ABOUT US</h4>
          <Link to="/about">About</Link>
          <Link to="/blog">
            Blog
          </Link>
          <Link to="mailto:nigeria@aiesec.net">Contact Us</Link>
        </div>
        <div>
          <h4>FOR YOUTHS</h4>
          <Link to="https://aiesec.org/global-volunteer" target="_blank">
            Global Volunteer
          </Link>
          <Link to="https://aiesec.org/global-talent" target="_blank">
            Global Talent
          </Link>
          <Link to="https://aiesec.org/global-teacher" target="_blank">
            Global Teacher
          </Link>
          <Link
            to="https://sites.google.com/aiesec.net/nationalvolunteer/"
            target="_blank"
          >
            National volunteer
          </Link>
          <Link to="/membership">Become a member</Link>
        </div>
        <div>
          <h4>ORGANIZATIONS</h4>
          <Link to="/for-organizations">Partner with us</Link>
        </div>
      </div>
      <div className={classes.bottomSection}>
        <div>&copy; AIESEC 2024</div>
      </div>
    </div>
  );
};

export default Footer;
