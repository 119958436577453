import classes from "./VideoPlayer.module.css";
import landingPageHeroSectionVideoOverlay from "../../Assets/Images/landingPageHeroSectionVideoOverlay.png";
import videoPlayButton from "../../Assets/Images/videoPlayButton.svg";
import { useEffect, useState } from "react";

const VideoPlayer = ({ height, url }) => {
  // States
  const [isPlaying, setIsPlaying] = useState(false);
  const [displayThumbnail, setDisplayThumbnail] = useState(true);

  // Utils
  const video = document.querySelector("video");
  const playPauseHandler = () => {
    if (video && !isPlaying) {
      video.play();
    } else if (isPlaying) {
      video.pause();
    }

    setIsPlaying((prevState) => {
      return !prevState;
    });
  };

  let timeout;

  const displayThumbnailHandler = () => {
    if (!isPlaying)
      timeout = setTimeout(() => {
        setDisplayThumbnail(true);
      }, 5000);
  };

  useEffect(() => {
    displayThumbnailHandler();

    // eslint-disable-next-line
  }, [isPlaying]);

  return (
    <div className={classes.container}>
      <video
        controls
        style={{ height }}
        id="video"
        onPlaying={() => {
          setIsPlaying(true);
          clearTimeout(timeout);
          setDisplayThumbnail(false);
        }}
        onPause={() => {
          setIsPlaying(false);
        }}
      >
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {!isPlaying && displayThumbnail && (
        <img
          src={landingPageHeroSectionVideoOverlay}
          alt="Thumbnail"
          className={classes.thumbNail}
        />
      )}
      {!isPlaying && (
        <img
          src={videoPlayButton}
          alt="Play button"
          onClick={playPauseHandler}
          className={classes.playPause}
        />
      )}
    </div>
  );
};

export default VideoPlayer;
