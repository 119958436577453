import Layout from '../../Components/Layout/Layout';
import GmwWaitlistPage from '../../Containers/GmwWaitlistPage/GmwWaitlistPage';

const Gmw2025Home = () => {
  return (
    <Layout>
      <GmwWaitlistPage />
    </Layout>
  );
};

export default Gmw2025Home;
