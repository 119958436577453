import React from 'react';
// import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContextProvider from './Context/AppContext';
import { Toaster } from 'react-hot-toast';
import axios from './config/axios.config';
import { configure } from 'axios-hooks';
import { HelmetProvider } from 'react-helmet-async';
import { hydrate, render } from 'react-dom';
import NvContextProvider from './Context/NvContext';

configure({ axios });

// const root = ReactDOM.createRoot(document.getElementById("root"));

const root = document.getElementById('root');

if (root.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <HelmetProvider>
        <AppContextProvider>
          {/* <NvContextProvider> */}
          <Toaster
            position="top-right"
            toastOptions={{
              duration: 5000,
              success: {
                style: {
                  background: 'green',
                  color: '#fff',
                },
              },
              error: {
                style: {
                  background: 'red',
                  color: '#fff',
                },
              },
            }}
          />
          <Router>
            <App />
          </Router>
          {/* </NvContextProvider> */}
        </AppContextProvider>
      </HelmetProvider>
    </React.StrictMode>,
    root
  );
} else {
  render(
    <React.StrictMode>
      <HelmetProvider>
        <AppContextProvider>
          <NvContextProvider>
            <Toaster
              position="top-right"
              toastOptions={{
                duration: 5000,
                success: {
                  style: {
                    background: 'green',
                    color: '#fff',
                  },
                },
                error: {
                  style: {
                    background: 'red',
                    color: '#fff',
                  },
                },
              }}
            />
            <Router>
              <App />
            </Router>
          </NvContextProvider>
        </AppContextProvider>
      </HelmetProvider>
    </React.StrictMode>,
    root
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
