import React from 'react'
import classes from "./BlogArticleTwo.module.css";
import BlogHeader1 from "../../Assets/Images/BlogHeader1.webp";
import BlogHeader2 from "../../Assets/Images/BlogHeader2.webp";
import BlogTitle from "../../Assets/Images/blogTitle.webp";
import BlogTitle2 from "../../Assets/Images/blogTitle2.webp";
import FeaturedBlogHeader1 from "../../Assets/Images/FeaturedBlogHeader1.webp";
import Author from '../../Assets/Images/author2.jpg';
import ShareButton from '../../Components/ShareButton/ShareButton';
import { Link } from 'react-router-dom';
import { scrollToTheTop } from "../../Utilities/scrollToTop";

function BlogArticleTwo() {
  return (
    <section className={classes.container}>
      <div className={classes.blogHeading}>
        <div className={classes.blogHeadingTitle}>
          <span>12th August, 2023</span>
          <h1>Dear Amazing Earth Hero</h1>
          <span className={classes.blogHeadingTitleCaption}>You deserve an <strong>A</strong> because you&apos;re an <strong>Awesome Earth Hero</strong></span>

          <div className={classes.blogHeadingTitleAuthor}>
            <img src={Author} alt="" />
            <span>By Siyo&apos;s Thought Bubbles</span>
          </div>

        </div>

        <div className={classes.blogHeadingImg}>
          <img src={BlogHeader1} alt="Group of Amazing Earth Heroes" />
        </div>
      </div>
      <div className={classes.blogContent}>

        <p>In the wide web of human experiences, “<em>firsts</em>” have always held a special allure — a beautiful blend of excitement and trepidation, a certain kind of anticipation and uncertainty. There is an undeniable energy that comes with a “first,” a good sort of anxiety (if that exists).</p>
        <p>In 1999, the UN made it a thing to raise awareness about the challenges and issues faced by young people worldwide. The youth, often hailed as the leaders of tomorrow, have a unique blend of passion, creativity, and resilience, and this is why <strong>International Youth Day</strong> exists.</p>
        <p>For AIESEC in Nigeria, as a youth-centered organization, celebrating International Youth Day is a testament to our recognition of the youth’s pivotal role in building a better tomorrow. Like I’ve said before, who would we be if we did not celebrate the people who make us who we are? So, it comes as no surprise that we will be having an event to do just that. We only apologize that it’s taken this long, but you know what they say: better late than never.</p>
        <img src={BlogTitle} alt="International Youth Day Flier" />

        <p>Each year, International Youth Day has a special theme that highlights different things important to young people&apos;s development and well-being — things like education, employment, mental health, gender equality, climate change, and more.</p>
        <p><strong>International Youth Day 2023 </strong>is themed <em>“Green Skills for Youths: Towards a Sustainable World.”</em> It reminds us to reduce, reuse, and recycle; it reminds us that this is our world and if we want to love living in it, we have to love taking care of it. Personally, I love that because how can I tell people to go outside and touch grass if I’m not taking steps to make sure the grass is still there?</p>
        <img src={BlogTitle2} alt="B-Build Green Skills Flier" />

        <p>AIESEC Nigeria’s first International Youth Day event holds in it the promise of igniting a flame that will illuminate the path for countless others to come.</p>
        <p>Before we sign out, we have some really cool super suits for the Amazing Earth Heroes (in case you’re wondering who that is, it’s you 😀)</p>
        <iframe width="100%" height="510" src="https://www.youtube.com/embed/yaVhS7APPTw" title="International Youth Day Cool Suits" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <p><a href="http://bit.ly/ainmerch" target="_blank" rel="noopener noreferrer">Get Yours Now!</a></p>
        <p>
          Follow us on all our social media platforms to stay tuned 💙</p>
        <div className={classes.shareBlogContent} >

          <span>Share this post</span>
          <ShareButton text="Check out this amazing article! &quot;Dear Amazing Earth Hero&quot;" linktext="http://aiesec.ng/blog/2" />
        </div>
      </div>
      <div className={classes.blogsRelated}>
        <h4>More Articles</h4>
        <div className={classes.blogsRelatedContainer}>
          <Link onClick={scrollToTheTop} className={classes.featuredBlogLinkContainer} to="/blog/1">
            <div className={classes.blogCard}>
              <div className={classes.blogCardImgContainer}>
                <img className={classes.featuredBlogImg} src={FeaturedBlogHeader1} alt="Featured Blog Header" />
              </div>
              <div className={classes.blogCardContainer}>
                <h4 className={classes.blogCardTitle}>I Want To Change The World. How About You?</h4>
                <p className={classes.featuredBlogDesc}>I don't think there has ever been a prouder time to be a young Nigerian living in Nigeria than now, and no, I'm not being sarcastic. For as long as I can remember, the proverb “Youths are the leaders of tomorrow” has been met with jeers and laughter whenever it is said out loud.</p>
                <Link onClick={scrollToTheTop} className={classes.featuredBlogLink} to="/blog/1">
                  <span>Read More  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      opacity="0.7"
                      d="M1.5 10.5L11 1M11 1H4.18868M11 1V7.63208"
                      stroke="#037EF3"
                      stroke-width="1.43396"
                    />
                  </svg>
                </Link>
              </div>
            </div>

          </Link>
          <Link onClick={scrollToTheTop} className={classes.featuredBlogLinkContainer} to="/blog/3">

            <div className={classes.blogCard}>
              <div className={classes.blogCardImgContainer}>
                <img className={classes.featuredBlogImg} src={BlogHeader2} alt="Featured Blog Header" />
              </div>
              <div className={classes.blogCardContainer}>

                <h4 className={classes.blogCardTitle}>The Superheroes Among Us</h4>
                <p className={classes.featuredBlogDesc}>When you hear the word “superhero”, what comes to mind? I bet it’s the picture of a man in a blue and red costume with chiselled abs, a cape and an S on the chest. It could even be a woman in an armour-like costume, wicked boots and a rope by her waist.</p>
                <Link onClick={scrollToTheTop} className={classes.featuredBlogLink} to="/blog/3">
                  <span>Read More  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      opacity="0.7"
                      d="M1.5 10.5L11 1M11 1H4.18868M11 1V7.63208"
                      stroke="#037EF3"
                      stroke-width="1.43396"
                    />
                  </svg>
                </Link>
              </div>

            </div>

          </Link>
        </div>
      </div>
    </section>
  )
}

export default BlogArticleTwo