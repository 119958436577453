import { useEffect } from "react";
import { useState } from "react";
import classes from "./LandingPageTestimonials.module.css";

const LandingPageTestimonials = () => {
  // Faqs
  const [faqs, setFaqs] = useState([
    {
      text: "\"I was initially stuck choosing between the AIESEC Exchange program and two other offers at the time. I am glad I didn't trade it for any of the other two because it opened international doors for me. You know, I was shortlisted for a fellowship in the US and awarded two scholarships for my Master's. I also got shortlisted for an African women's tech program. I was placed on several reserves that I had to reject. I needed that experience to advance professionally and personally, and I am grateful to God and AIESEC that it happened.\"",
      name: "Precious",
      role: "Global Volunteer Experience in Dubai, United Arab Emirates",
      isActive: false,
    },

    {
      text: '"Impact is relative, but for me, being a primary school teacher gave me the opportunity to really face one of my fears which is handling kids. \n And with the way the education system there was structured, I was able to connect to each student individually in the process of teaching them, helping them learn at their own pace \n Being able to impart knowledge into the students, while having fun with them, as well as the reactions from them when I had to leave, showed how much of an experience it was for them and also for me. So I\'m quite sure I made some impact."',
      name: "Olakunle",
      role: "Global Volunteer Experience, Kumasi, Ghana",
      isActive: false,
    },
    {
      text: "  “I hoped to impact society with my project, which at the time, was being a nursery, primary and secondary school English Language Teacher. I also wanted to experience a new culture, the people, the environment, and I also expected to polish my French, and all of that worked out.”",
      name: "Grace",
      role: "English Language Teacher in Gasa, Benin Republic",
      isActive: false,
    },
  ]);
  const [activeIndex, setActiveIndex] = useState(0);

  const increaseActiveIndex = () => {
    if (activeIndex < faqs.length)
      setActiveIndex((prevState) => {
        return prevState + 1;
      });
  };

  const decreaseActiveIndex = () => {
    if (activeIndex >= 0)
      setActiveIndex((prevState) => {
        return prevState - 1;
      });
  };

  useEffect(() => {
    const faqsCopy = faqs.map((data, i) => {
      if (i === activeIndex) {
        return { ...data, isActive: true };
      } else {
        return { ...data, isActive: false };
      }
    });

    if (activeIndex > faqs.length - 1) {
      setActiveIndex(faqs.length - 1);
    }

    if (activeIndex <= 0) {
      setActiveIndex(0);
    }

    setFaqs(faqsCopy);

    // eslint-disable-next-line
  }, [activeIndex]);

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <div> </div>
        <div>
          <p>
            {
              faqs.find((data) => {
                return data.isActive;
              })?.text
            }
          </p>
          <p>
            <span>
              {
                faqs.find((data) => {
                  return data.isActive;
                })?.name
              }
            </span>

            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="4"
                height="4"
                viewBox="0 0 4 4"
                fill="none"
              >
                <circle cx="1.99989" cy="1.99951" r="2" fill="#C4C4C4" />
              </svg>
            </span>
            <span>
              {
                faqs.find((data) => {
                  return data.isActive;
                })?.role
              }
            </span>
          </p>
          <div className={classes.navButtons}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="17"
              viewBox="0 0 9 17"
              fill="none"
              onClick={decreaseActiveIndex}
            >
              <path
                d="M8 1L1 8.5L8 16"
                stroke={activeIndex !== 0 ? "#1D2129" : "#A0A0A0"}
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="17"
              viewBox="0 0 9 17"
              fill="none"
              onClick={increaseActiveIndex}
            >
              <path
                d="M1 1L8 8.5L1 16"
                stroke={faqs.length - 1 === activeIndex ? "#A0A0A0" : "#1D2129"}
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageTestimonials;
