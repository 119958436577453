import ProductsContainer from "../../Components/ProductsContainer/ProductsContainer";
import globalTalent from "../../Assets/Images/globalTalent.svg";
import gv2 from "../../Assets/Images/gv2.svg";
import nv from "../../Assets/Images/nv.svg";
import globalTeacher from "../../Assets/Images/globalTeacher.svg";
import globalTalent1 from "../../Assets/Images/Global Talent 1.jpeg";
import globalTalent2 from "../../Assets/Images/Global Talent 2.jpeg";
import globalTalent3 from "../../Assets/Images/Global Talent 3.jpeg";
import globalTalent4 from "../../Assets/Images/Global Talent 4.jpeg";

import globalVolunteer1 from "../../Assets/Images/Global Volunteer 1.jpeg";
import globalVolunteer2 from "../../Assets/Images/Global Volunteer 2.jpeg";
import globalVolunteer3 from "../../Assets/Images/Global Volunteer 3.jpeg";
import globalVolunteer4 from "../../Assets/Images/Global Volunteer 4.jpeg";

import globalTeacher1 from "../../Assets/Images/Global Teacher 1.jpeg";
import globalTeacher2 from "../../Assets/Images/Global Teacher 2.jpeg";
import globalTeacher3 from "../../Assets/Images/Global Teacher 3.jpeg";
import globalTeacher4 from "../../Assets/Images/Global Teacher 4.jpeg";

const LandingPageProducts = () => {
  // Utils
  const AiNProducts = [
    {
      bgColor: "#F85A40",
      productLogo: gv2,
      url: "https://aiesec.org/global-volunteer",
      section: "student",
      header: "Volunteer with AIESEC",
      introParagraph:
        "Develop your leadership through a volunteering project abroad, contributing to one of the Sustainable Development Goals",
      productDetails: [
        {
          name: "Organizations",
          content: "A school, summer camp or an NGO",
        },
        {
          name: "Duration",
          content: "For 6 to 8 weeks",
        },
      ],
      popularOppurtunities: [
        {
          name: "Global Classroom",
          location: "32 locations available",
          backgroundImage: globalVolunteer1,
        },
        {
          name: " Global Classroom",
          location: "1 location available",
          backgroundImage: globalVolunteer2,
        },
        {
          name: "Youth 4 Impact",
          location: "16 locations available",
          backgroundImage: globalVolunteer3,
        },
        {
          name: "Skill Up!",
          location: "10 locations available",
          backgroundImage: globalVolunteer4,
        },
      ],
    },
    {
      bgColor: "#0CB9C1",
      productLogo: globalTalent,
      section: "graduate",
      url: "https://aiesec.org/global-talent",
      header: "Intern with AIESEC",
      introParagraph:
        "Develop your leadership while boosting your career prospects with an international internship in your field",
      productDetails: [
        {
          name: "Organizations",
          content: "A company, school or institution",
        },
        {
          name: "Duration",
          content: "For 3 to 12 months",
        },
      ],
      popularOppurtunities: [
        {
          name: "Spanish speaking – Service Desk Analyst",
          location: "Budapest, Hungary",
          backgroundImage: globalTalent1,
        },
        {
          name: "Regional Supply Planner Jr",
          location: "Budapest, Hungary",
          backgroundImage: globalTalent2,
        },
        {
          name: "Sales Intern",
          location: "Buenos Aires, Argentina",
          backgroundImage: globalTalent3,
        },
        {
          name: "English Teacher",
          location: "Selçuklu, Türkiye",
          backgroundImage: globalTalent4,
        },
      ],
    },
    {
      bgColor: "#F48924",
      productLogo: globalTeacher,
      section: "organization",
      url: "https://aiesec.org/global-teacher",
      header: "Teach with AIESEC",
      introParagraph:
        "Develop your leadership through international teaching opportunities",
      productDetails: [
        {
          name: "Organizations",
          content: "A school or institution",
        },
        {
          name: "Duration",
          content: "For 3 to 12 months",
        },
      ],
      popularOppurtunities: [
        {
          name: "English Teacher",
          location: "Selçuklu, Türkiye",
          backgroundImage: globalTeacher1,
        },
        {
          name: "English Teacher",
          location: "Cúcuta, Colombia",
          backgroundImage: globalTeacher2,
        },
        {
          name: "Female English Teacher",
          location: "Gurugram, India",
          backgroundImage: globalTeacher3,
        },
        {
          name: " Nursery Assistant",
          location: "Budapest, Hungary",
          backgroundImage: globalTeacher4,
        },
      ],
    },
    {
      bgColor: "#155D27",
      productLogo: nv,
      url: "https://sites.google.com/aiesec.net/nationalvolunteer/",
      section: "country",
      header: "Volunteer within the country",
      introParagraph:
        "Develop your leadership through a volunteering project within Nigeria, contributing to one of the Sustainable Development Goals",
      productDetails: [
        {
          name: "Organizations",
          content: "A school or institution",
        },
        {
          name: "Duration",
          content: "For 2 weeks",
        },
      ],
      popularOppurtunities: null,
    },
  ];

  return (
    <div id="student">
      {AiNProducts.map((data, i) => {
        return (
          <ProductsContainer
            logo={data?.productLogo}
            url={data.url}
            section={data.section}
            backgroundColor={data.bgColor}
            header={data.header}
            introParagraph={data.introParagraph}
            productDetails={data.productDetails}
            popularOppurtunities={data.popularOppurtunities}
          />
        );
      })}
    </div>
  );
};

export default LandingPageProducts;
