/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import classes from "./ForOrganizationsDrawer.module.css";
import { Form, Input, Select, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Drawer, message } from 'antd';
import Button from "../../Components/Button/Button";
import successfulTick from '../../Assets/Images/successfulTick.svg'
const ForOrganizationsDrawer = ({ open, onClose }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [unsuccessful, setUnsuccessful] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();

    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'This is an error message',
        });
    };
    // https://api.sheetmonkey.io/form/kzZpNcgjM3tab6x54MyhE5
    const onFinish = (values) => {
        setLoading(true);
        fetch('https://ainbackend.fly.dev/api/partnership', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((result) => {
            // Handle the result
            if (result && result.status === 200) {
                setLoading(false);
                setSuccess(true)
            }
            if ((result && result.status === 400) ||( result && result.status === 500)) {
                setLoading(false);
                setUnsuccessful(true)
            }
            console.log(result)
            console.log(result.body)
        }).catch((err) => {
            // Handle the error

            setLoading(false);
            error();
            console.log(err);
        });
    }

    const title = (
        <div className={classes.drawerTitle} style={success || unsuccessful ? { display: "none" } : { display: "block" }}>
            <h4>Become a partner</h4>
            <p>Fill the form below to become a partner. We will reach back to you regarding our partnership opportunities</p>
        </div>
    )

    return (
        <>
            <Drawer rootClassName={classes.drawerRoot} className={classes.drawer} title={title} width={"380px"} onClose={onClose} open={open}>
                {(success) || (unsuccessful) ? (
                    <div className={classes.successful}>
                        <img src={successfulTick} alt="successful Tick Icon" />
                        {unsuccessful ? (
                            <div>
                                <h4>Thank You!</h4>
                                <p>We have already saved your details. We would reach back to you via email regarding the next steps.</p>
                            </div>
                        ) :
                            (<div>
                                <h4>Successful!</h4>
                                <p>We have successfully registered your details. We would reach back to you via email regarding the next steps.</p>
                            </div>)}
                    </div>
                ) : (
                    <Form
                        layout={"vertical"}
                        form={form}
                        onFinish={onFinish}
                        name="partnerData"
                        requiredMark={false}
                        className={classes.drawerForm}
                    >

                        <Form.Item rules={[
                            {
                                required: true,
                                message: 'Enter your email address',
                            },
                            {
                                type: 'email',
                                message: 'Enter a valid Email address',
                            }
                        ]} name="email" className={classes.drawerFormLabel} label="Enter your email address ">
                            <Input placeholder="Email Address" />
                        </Form.Item>
                        <Form.Item rules={[
                            {
                                required: true,
                                message: 'Enter your company name',
                            },
                        ]} name="company" className={classes.drawerFormLabel} label="What company are you representing?">
                            <Input placeholder="Enter company name" />
                        </Form.Item>
                        <Form.Item rules={[
                            {
                                required: true,
                                message: 'Choose a program',
                            },
                        ]} name="program" className={classes.drawerFormLabel} label="Which of our programs do you want to partner on?">
                            <Select
                                className={classes.drawerFormSelect}
                                defaultValue="Select Program"
                                options={[
                                    { value: 'select_program', label: 'Select Program' },
                                    { value: 'Global Talent', label: 'Global Talent' },
                                    { value: 'Global Volunteer', label: 'Global Volunteer' },
                                    { value: 'Global Teacher', label: 'Global Teacher' },
                                    { value: 'National Volunteer', label: 'National Volunteer' }
                                ]}

                            />
                        </Form.Item>
                        <Form.Item className={classes.drawerFormBtn} >
                            <Button htmlType="submit" type="primary"> {loading ? (<Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />} />) : "Submit"} </Button>
                        </Form.Item>
                    </Form>

                )}


            </Drawer>
        </>

    );
};
export default ForOrganizationsDrawer;