import classes from './NvProjectsPageProjects.module.css';
import { useState } from 'react';
import project1 from '../../Assets/Images/project1.jpeg';
import project2 from '../../Assets/Images/project2.svg';
import project3 from '../../Assets/Images/project3.png';
import project4 from '../../Assets/Images/project4.svg';
import project5 from '../../Assets/Images/project5.svg';
import project6 from '../../Assets/Images/project6.svg';
import project7 from '../../Assets/Images/project7.png';
import project8 from '../../Assets/Images/project8.svg';
import project9 from '../../Assets/Images/project9.png';
import project10 from '../../Assets/Images/project10.svg';
import project12 from '../../Assets/Images/project12.png';
import project13 from '../../Assets/Images/project13.png';
import project14 from '../../Assets/Images/project14.png';
import project15 from '../../Assets/Images/project15.png';
import project16 from '../../Assets/Images/project16.png';
import project17 from '../../Assets/Images/project17.png';
import project18 from '../../Assets/Images/project2.svg';
import project19 from '../../Assets/Images/project19.png';
import project20 from '../../Assets/Images/project20.png';
import project21 from '../../Assets/Images/project21.png';
import project22 from '../../Assets/Images/project22.svg';
import project23 from '../../Assets/Images/project23.png';
import project24 from '../../Assets/Images/project24.png';
import project25 from '../../Assets/Images/project25.png';
import project26 from '../../Assets/Images/project26.png';
import project27 from '../../Assets/Images/project27.png';
import project28 from '../../Assets/Images/project28.png';

const NvProjectsPageProjects = () => {
  const [selected, setSelected] = useState(null);
  const projects = [
    {
      title: 'Project Rural Child Education',
      image: project1,
      location: 'Abeokuta',
      desc: 'This project aims to help to educate the kids in low-income communities with basic numearcy and literacy knowledge who have never been to schools. ',
      req: 'Basic knowledge of literacy and numeracy. Ability to communicate in local language (Yoruba)',
      side: ' Meal on arrival, Send forth party, Visit to FUNAAB zoo',
      hours: '3 weekdays and Saturday',
      duration: '2 weeks',
    },
    {
      title: 'Educating a Child',
      image: project2,
      location: 'Ife',
      desc: 'The project is aimed at setting up extracurricular activities which includes sex education, career and leadership development and debate. Equipping school students with the knowledge of these things which are crucial but are not taught in schools. ',
      req: 'Must have passion about child education, Must have basic knowledge of sex education, career and leadership development and debating.',
      side: ' Certificate of Participation, Exciting Tours, Movie Night',
      hours: 'Mon - Fri ; 7:30am - 2pm (7:30am - 1pm on Fridays)',
      duration: '2 weeks',
    },
    {
      title: 'Young Pillars',
      image: project3,
      location: 'Jos',
      desc: 'The project seeks to provide youths and children with quality education and relevant skills for growth and sustainability and the awareness to effect positive change in their environment. ',
      req: 'Passion for child education, Leadership skills',
      side: ' Get together/hangout, Certificate',
      hours: 'Mon - Fri : 9am - 4pm',
      duration: '2 weeks',
    },
    {
      title: 'Be More',
      image: project4,
      location: 'Jos',
      desc: 'The project is aimed at educating children on skills, entrepreneurship and other related areas so they are aware of vast opportunities, grow wholesomely and can gain fully contribute to world development. ',
      req: 'Passion for child education,Knowledge of a particular skill or vocation,Leadership skills',
      side: ' Get together/hangout, Certificate',
      hours: 'Mon - Fri : 3pm - 6pm; Sat : 9am - 4pm',
      duration: '2 weeks',
    },
    {
      title: 'Training Stars',
      image: project5,
      location: 'Ife',
      desc: 'This project seeks to advance SDG 4 & 6 by creating a learning space to further teach and engage the orphanage homes with curricular and extra-curricular topics i.e, entrepreneurship, digital skills, and other economically productive crafts. The project is also aimed at creating awareness about the why and how to ensure the availability and sustainable management of water and proper sanitation. ',
      req: 'Good communication skills, A teachable digital skill or craft and willingness to teach young ones ,Basic knowledge of proper sanitation, personal hygiene, career, and leadership development.',
      side: ' Certificate of Participation, Exciting Tours, Movie Night',
      hours: '3 weekdays : 3pm - 6pm; Saturday : 10am - 3pm',
      duration: '2 weeks',
    },
    {
      title: 'Educate the Next Generation',
      image: project6,
      location: 'Port Harcourt',
      desc: 'This project aims to teach to contribute and promote SD4 which is quality Education by teaching the next generation basic knowledge on economics and Information Communication Technology known as Data Processing to enhance their knowledge on it. ',
      req: 'Ability to transmit knowledge in the easiest way possible, Knowledge on Economics and Data Processing.',
      side: ' Get together/hangout, Certificate',
      hours: 'Mon - Fri : 3pm - 6pm; Sat : 9am - 4pm',
      duration: '2 weeks',
    },
    {
      title: 'Healthy Living',
      image: project7,
      location: 'Port Harcourt',
      desc: 'This project aims to teach children basic healthy habits, personal hygiene and the right measures to take when there is emergency. ',
      req: 'Passion to teach children, Basic Good Health and Well being knowledge which comprises personal hygiene tips, Healthy habits, and safety measures . ',
      side: 'First day transportation fare for all EPs, Tour, Refreshement.',
      hours: ' Monday - Friday 3pm- 5pm. Saturday  10am - 2pm',
      duration: '2 weeks',
    },
    {
      title: 'Inclusive Ed',
      image: project8,
      location: 'Enugu',
      desc: 'This project aims at increasing inclusive education for people living with disabilities by providing innovative ways of teaching them ',
      req: 'Empathy as a caregiver.   Patience. Adaptability. Practical. Dependability. ',
      side: ' Get together/hangout, Certificate',
      hours: ' Monday - Friday 3pm- 5pm. Saturday  10am - 2pm',
      duration: '2 weeks',
    },
    {
      title: 'Scale Up',
      image: project9,
      location: 'Akure',
      desc: 'The project aims to contribute to SDG #17 by building up the capacity of local NGO, non-profit organizations and/or foundations to contribute to social impact in their communities by collaborating with local social projects and engaging with grass-groot initiatives.  ',
      req: 'Adaptability. Must harbor no gender biases. Effective communication skills ',
      side: ' Get together/hangout,Certificate of participation, Movie night, Game night, Meal on arrival, Trip to Idanre hill',
      hours: ' Monday - Friday : 9am to 2pm',
      duration: '2 weeks',
    },
    {
      title: 'HIV Hotline and Counseling',
      image: project10,
      location: 'Lagos',
      desc: 'A telephone hotline project that involves receiving calls from people all over the country who need information about HIV/AIDS. The goal is to provide factual, helpful information and possibly connect them to the help they need.  it also caters to people who need counselling on how to take tests, go for checkups and more.',
      req: 'Basic knowledge of literacy and numeracy. Ability to communicate in local language (Yoruba). ',
      hours: ' Monday - Friday : 9am to 4pm',
      duration: '2 weeks',
    },
    {
      title: 'Edify',
      image: project12,
      location: 'Enugu',
      desc: 'This project aims to provide string support for child education and promote SDG4 which is quality education ',
      req: 'Excitement to teach children, Basic literacy and numeracy knowledge',
      side: ' Hangout, Certificate, cultural experience',
      hours: ' Tuesday - Friday 8am - 2pm',
      duration: '2 weeks',
    },
    {
      title: 'VestUp',
      image: project13,
      location: 'Enugu',
      desc: 'This project aims to accomplish SDG 8 (decent work and economic growth) by facilitating clubs in schools that are about building knowledge on finance, investment and enterprenuership which would develop the financial capacity of young people. ',
      req: 'Ability to impart knowledge, Basic knowledge on entrepreneurship and finance',
      side: ' Hangout, Certificate of participation, cultural experience',
      hours: ' Tuesday - Friday 8am - 2pm',
      duration: '2 weeks',
    },
    {
      title: 'Youth Agri-Pioneers',
      image: project14,
      location: 'Enugu',
      desc: 'Seeking sustainable solutions to end hunger in all its forms and to achieve food security, improved nutrition and promote sustainable agriculture to ensure that everyone everywhere has enough good-quality food to lead a healthy life. ',
      req: 'Backround in education and agriculture',
      side: ' Hangout, Certificate of participation, cultural experience',
      hours: ' Tuesday - Friday 10am - 3pm',
      duration: '2 weeks',
    },
    {
      title:
        'Promoting accountable governance for effective service delivery (PAGES)',
      image: project15,
      location: 'Enugu',
      desc: 'This project aims to contribute to SDG #10 by promoting policies that prioritize the needs, rights, and well-being of women and youth, creating policies that effectively manage palliatives, and to pass social protection bills for women and children ',
      req: 'Background in social works will be a plus',
      side: ' Hangout, Certificate of participation, cultural experience',
      hours: ' Tuesday - Friday 10am - 3pm',
      duration: '2 weeks',
    },
    {
      title: 'For the Health of it',
      image: project16,
      location: 'Enugu',
      desc: 'This project aims to ensure healthy living and promote well-being for all ages ',
      req: 'Good communication skills, Patience',
      side: ' Hangout, Certificate of participation, cultural experience',
      hours: 'Flexible',
      duration: '2 weeks',
    },
    {
      title: 'Edurural',
      image: project17,
      location: 'Benin',
      desc: 'The project aims to ensure inclusive and equitable quality education and promote learning opportunities for members of the community especially in rural or developing areas.	 ',
      req: 'Passion for child education, Good communication skills, Adaptabilty and patience.',
      side: ' Hangout, Certificate of participation, cultural experience, Exciting Tours, Movie Night and fun.',
      hours: 'Flexible',
      duration: '2 weeks',
    },
    {
      title: 'Skill Up',
      image: project18,
      location: 'Benin',
      desc: 'The project aims to provide the opportunity for members in the community to learn a modern and in-demand skill.	 ',
      req: 'Skilled individuals who are experienced in either soft or manual skills.',
      side: ' Hangout, Certificate of participation, cultural experience, Exciting Tours, Movie Night and fun.',
      hours: 'Flexible',
      duration: '2 weeks',
    },
    {
      title: 'Building healthier communities',
      image: project19,
      location: 'Calabar',
      desc: 'The  project aims to address the challenges faced by individuals living with cancer, aligning with Sustainable Development Goal 3 Good Health and Well-being. Through a multi-faceted approach, the project seek to improve access to quality healthcare services, enhance support systems, and promote awareness and education about cancer prevention and treatment.	 ',
      req: 'Reliability and commitment,Communication skills,Passion and empathetic, physical and emotional resilience.',
      side: ' Cross-cultural experience.',
      hours: 'Flexible',
      duration: '2 weeks',
    },
    {
      title: 'Edu Innovation Hub',
      image: project20,
      location: 'Calabar',
      desc: 'The aim of this project is to substantially increase the number of young children and teenagers  who have relevant skills,  including technical and vocstionsl skill, for employment, decent jobs, and  entrepreneurship.	 ',
      req: 'Good communication skills, Ability to impact  knowledge.',
      side: ' Cross-cultural experience.',
      hours: 'Mondays - Fridays, 9am to 5pm',
      duration: '2 weeks',
    },
    {
      title: 'Teach the Child',
      image: project21,
      location: 'Benue',
      desc: 'The project aim is to provide children and young people with quality and easy access to education plus other learning opportunities, to achieve universal literacy and numeracy. A major component in acquiring knowledge and valuable skills in the learning environment.	 ',
      req: 'Good communication skills, Ability to impact  knowledge.',
      side: ' Cross-cultural experience.',
      hours: 'Mondays - Fridays, 8am to 4pm',
      duration: '2 weeks',
    },
    {
      title: 'Helping Hands',
      image: project22,
      location: 'Benue',
      desc: 'The project aims to contribute to SDG #3 by providing caregiving to sick persons in the hospital, helping in daily operation of the hospital and assisting the medical personnel in the facility with daily rountines.	 ',
      req: 'Must be in the Health Sector or studying health related  course and volunteer will be working in a clinic',
      side: ' Cross-cultural experience, Weekend Hangout',
      hours: 'Mondays - Fridays, including night shifts',
      duration: '2 weeks',
    },
    {
      title: 'Capacity strengthening workshop for changemakers',
      image: project23,
      location: 'Abuja',
      desc: 'The aim of the project is to improve the literacy and numeracy skills for young learners in public primary and secondary schools in Nigeria by enhancing the capacity of public school educators in inclusive education and 21st-century teaching methods.	 ',
      req: 'Good communication skills and volunteers, experience with either photography or videography',
      side: ' Cross-cultural experience',
      hours:
        'Monday and Wednesday,10am to 3pm,  Monday, Wednesday and Thursday, 10am to 4pm are for field work',
      duration: '2 weeks',
    },
    {
      title: 'Tech Club Project',
      image: project24,
      location: 'Abuja',
      desc: 'The project is aimed at empowering students in 20 schools across 5 area councils in Abuja with essential digital technology skills.	 ',
      req: 'Strong understanding of digital technology, Excellent communication skills, Patience, and The ability to work with students of varying ages and backgrounds. Prior teaching experience or experience working with youth is advantageous.',
      side: ' Cross-cultural experience, Weekend Hangout',
      hours: 'Monday to Thursday 10am to 4pm',
      duration: '2 weeks',
    },
    {
      title: 'Skillify',
      image: project25,
      location: 'Port Harcourt',
      desc: 'This project aims to provide children with holistic learning experiences by focusing on teaching craft and handy skills. The initiative seeks to enhance creativity and practical knowledge, giving children hands-on experience in various crafts. By integrating craft education into the curriculum, the project aims to foster a love for learning and develop practical skills. ',
      req: 'Passionate about working with children, adept in communication, and equipped with transferable skills including knitting, needlework, baking, hairdressing, arts, crafts, and more.',
      side: ' Cross-cultural experience',
      hours: '3 Weekdays',
      duration: '2 weeks',
    },
    {
      title: 'Green Initiative',
      image: project26,
      location: 'Ilorin',
      desc: 'The project  aims at promoting  sustainable practices like  plastic recycling, and educating the communities on climate change. ',
      req: 'Passionate about climate and environment, Have digital skills like photography and soft skills like public speaking',
      side: ' Cross-cultural experience',
      hours: 'Flexible',
      duration: '2 weeks',
    },
    {
      title: 'Empower Together',
      image: project27,
      location: 'Ibadan',
      desc: 'This project aims to leverage on the skills and dedication of volunteers to support educational  initiatives for children, provide financial aid through sponsors and donations and meet the basic needs of underprivileged communities. With this project, volunteers contribute towards the NGO cause of empowering children, youth and the community at large ',
      req: 'Passionate about empowerment initiatives, committed, Willing to learn and participate.Should have basic skills such as beginner-level social media management. ',
      side: ' Cross-cultural experience, Hangouts with AiI members. Certificate of participation',
      hours:
        '3 weekdays and Saturday(for outreach and time is flexible); 9am to 3pm',
      duration: '2 weeks',
    },
    {
      title: 'Skill Up',
      image: project28,
      location: 'Enugu',
      desc: 'The project aims to impact young people by providing them with hard skills trainings (such as baking, photography, fashion designing, etc) needed to qualify for a decent job, and career guidance.  ',
      req: 'Excellent communication skills, Possess transferable skills such as sewing, photography, hair dressing, baking, etc ',
      side: ' Cross-cultural experience. Certificate of participation.',
      hours: 'Tuesday to Friday 10am - 4pm,',
      duration: '2 weeks',
    },
  ];

  return (
    <div className={classes.container}>
      {projects.map((data, i) => {
        return (
          <div key={i} className={classes.detailsContainer}>
            <div style={{ background: `url("${data.image}")` }}>
              {data.title}
              <div className={classes.detailsButton}>
                <p
                  onClick={() => {
                    selected === data?.title
                      ? setSelected('')
                      : setSelected(data?.title);
                  }}
                >
                  Details
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="92"
                    height="92"
                    viewBox="0 0 92 92"
                    id="down-arrow"
                    fill="none"
                  >
                    <path
                      d="M73.8 57.9l-25 24.9C48 83.6 47 84 46 84s-2-.4-2.8-1.2l-25-24.9c-1.6-1.6-1.6-4.1 0-5.7 1.6-1.6 4.1-1.6 5.7 0L42 70.4V12c0-2.2 1.8-4 4-4s4 1.8 4 4v58.4l18.2-18.1c1.6-1.6 4.1-1.6 5.7 0 1.5 1.5 1.5 4-.1 5.6z"
                      fill="fff"
                    ></path>
                  </svg> */}
                </p>
              </div>
            </div>
            {selected === data?.title && (
              <div className={classes.detailsBlock}>
                <p>
                  <span className={classes.detailsText}>Location: </span>
                  {data?.location}
                </p>
                <p>
                  <span className={classes.detailsText}>
                    Project Description:
                  </span>
                  <br />
                  {data?.desc}
                </p>
                <p>
                  <span className={classes.detailsText}>
                    Project Requirements:
                  </span>
                  <br />
                  {data?.req}
                </p>
                <p>
                  <span className={classes.detailsText}>Side Attractions:</span>{' '}
                  <br />
                  {data?.side}
                </p>
                <p>
                  <span className={classes.detailsText}>Working Hours: </span>
                  {data?.hours}
                </p>
                <p>
                  <span className={classes.detailsText}>Duration: </span>
                  {data?.duration}
                </p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default NvProjectsPageProjects;
