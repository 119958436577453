
import styled from 'styled-components';

const LoadingPageContainer = styled.section`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #7552cc;

	.lds-ring {
		display: inline-block;
		position: relative;
		width: 60px;
		height: 60px;
	}
	.lds-ring div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 60px;
		height: 60px;
		margin: 8px;
		border: 8px solid #7552cc;
		background: #7552cc;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #fff #fff rgba(196, 196, 196, 0.3) rgba(196, 196, 196, 0.3);
	}
	.lds-ring div:nth-child(1) {
		animation-delay: -0.45s;
	}
	.lds-ring div:nth-child(2) {
		animation-delay: -0.3s;
	}
	.lds-ring div:nth-child(3) {
		animation-delay: -0.15s;
	}
	@keyframes lds-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	.copyright__text__loading {
		position: fixed;
		bottom: 30px;
		left: 50%;
		transform: translateX(-50%);
		font-style: normal;
		font-weight: 300;
		font-size: 0.875rem;
		line-height: 17px;
		color: #fff;
	}
`;

const LoadingPage = () => {
	return (
		<LoadingPageContainer id='loading__page'>
			<div className='lds-ring'>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>

		</LoadingPageContainer>
	);
};

export default LoadingPage;
