import classes from './SideNav.module.css';
import AiNLogoBlue from '../../Assets/Images/AiNLogoBlue.svg';
import { Link, useLocation } from 'react-router-dom';
import { scrollToTheTop } from '../../Utilities/scrollToTop';
import { activteToggler } from '../../HelperFunctions/activeToggler';
import { useState } from 'react';

const SideNav = ({ closeSideNav, routes }) => {
  //   Location
  const location = useLocation();

  const [innerRoutes, setInnerRoutes] = useState(routes);

  return (
    <div className={classes.container}>
      <div className={classes.sideNavHeader}>
        <img src={AiNLogoBlue} alt="AIESEC in Nigeria Logo" loading="lazy" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          onClick={closeSideNav}
        >
          <path
            d="M1.00012 13.0002L13.0001 1.00028"
            stroke="#037EF3"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M13 13L1.00004 1.00004"
            stroke="#037EF3"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </div>
      <div className={classes.linksSection}>
        {innerRoutes.map((route, i) => {
          if (route.dropdownOptions) {
            return (
              <div
                key={i}
                onClick={() => {
                  activteToggler(i, innerRoutes, setInnerRoutes);
                }}
              >
                <span>{route.title}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    d="M12.0063 14.6953L17.0063 9.69531H7.00635L12.0063 14.6953Z"
                    fill="#2E3A59"
                  />
                </svg>

                <div
                  className={classes.dropdown}
                  style={
                    route.isActive
                      ? { maxHeight: '700px' }
                      : { maxHeight: '0px' }
                  }
                >
                  {route.dropdownOptions.map((subRoute, j) => {
                    return (
                      <Link
                        to={subRoute.route}
                        key={j}
                        className={
                          !subRoute.isActive ? classes.inActive : classes.active
                        }
                      >
                        {subRoute.title}
                      </Link>
                    );
                  })}
                </div>
              </div>
            );
          } else
            return (
              <>
                <Link to={route.route} key={i} onClick={scrollToTheTop}>
                  <span>{route.title}</span>
                  {route?.relatedRoutes.includes(
                    location.pathname.slice(1)
                  ) && (
                    <span className={classes.activeRouteIndicator}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="72"
                        height="13"
                        viewBox="0 0 72 13"
                        fill="none"
                      >
                        <path
                          d="M7.47767 8.24647C23.1049 6.1167 70.1407 1.28052 54.5983 3.96024C43.5324 5.86814 32.1767 6.19474 21.0648 8.02235C15.3574 8.96106 8.72126 11.1112 2.88328 10.7678C-1.46925 10.5118 11.4544 9.1475 15.77 8.52662C33.8187 5.92999 51.8894 4.13235 70.0064 2.19531"
                          stroke="#037EF3"
                          strokeWidth="3"
                          strokeLinecap="round"
                        />
                      </svg>
                    </span>
                  )}
                </Link>
              </>
            );
        })}
      </div>
    </div>
  );
};

export default SideNav;
