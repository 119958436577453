import classes from "./ProductsContainer.module.css";
import { useRef } from "react";

const ProductsContainer = ({
  backgroundColor,
  header,
  introParagraph,
  productDetails,
  popularOppurtunities,
  logo,
  url,
  section,
}) => {
  // Ref
  const oppurtunityRef = useRef(null);

  const scrollLeft = () => {
    if (oppurtunityRef.current) oppurtunityRef.current.scrollLeft -= 300;
  };
  const scrollRight = () => {
    if (oppurtunityRef.current) oppurtunityRef.current.scrollLeft += 300;
  };

  return (
    <div
      className={classes.container}
      style={{
        backgroundColor,
      }}
      id={section}
    >
      <div className={classes.topImage}>
        <img src={logo} alt={header} loading="lazy" />
      </div>
      <div className={classes.containerMain}>
        <div>
          <h2>{header}</h2>
          <p>{introParagraph}</p>
          <div className={classes.productMoreDetails}>
            {productDetails?.map((data, i) => {
              return (
                <div key={i}>
                  <div></div>
                  <div>
                    <h5>{data.name}</h5>
                    <p>{data.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.learnMore}>
          <a href={url} target="_blank" rel="noreferrer">
            Learn More{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                opacity="0.7"
                d="M1.15991 10.5L10.6599 1M10.6599 1H3.84859M10.6599 1V7.63208"
                stroke="white"
                strokeWidth="1.43396"
              />
            </svg>
            
          </a>
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="125"
              height="9"
              viewBox="0 0 125 9"
              fill="none"
            >
              <path
                d="M1 7.94615C29.5101 7.94615 57.8234 2.32561 86.4499 2.11512C98.3222 2.02783 110.086 1.95706 121.94 2.11512C125.151 2.15794 125.009 1.52784 121.796 1.46722C116.092 1.3596 110.85 0.378442 105.023 0.675354C94.2642 1.22357 83.4704 0.831205 72.7001 1.32324C50.7367 2.32664 28.7512 1.95116 6.83105 2.76301"
                stroke="white"
                strokeLinecap="round"
              />
            </svg>
        </div>
      </div>

      {popularOppurtunities && (
        <div className={classes.popularOppurtunities}>
          <div
            className={classes.popularOppurtunitiesInner}
            ref={oppurtunityRef}
          >
            <h4>Popular Opportunities</h4>
            <div>
              {popularOppurtunities?.map((oppurtunuty, i) => {
                return (
                  <div className={classes.oppurtunuty} key={i}>
                    <img
                      src={oppurtunuty.backgroundImage}
                      alt={oppurtunuty.name}
                      loading="lazy"
                    />
                    <div className={classes.oppurtunutyText}>
                      <h6>{oppurtunuty.name}</h6>
                      <p>{oppurtunuty.location}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {popularOppurtunities && (
            <div className={classes.toggleScroll}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="17"
                viewBox="0 0 9 17"
                fill="none"
                onClick={scrollLeft}
              >
                <path d="M8 1L1 8.5L8 16" stroke="#A0A0A0" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="17"
                viewBox="0 0 9 17"
                fill="none"
                onClick={scrollRight}
              >
                <path d="M1 1L8 8.5L1 16" stroke="#A0A0A0" />
              </svg>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductsContainer;
