import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import { capitalize } from "../../HelperFunctions/capitalize";
import classes from "./LandingPageNewsLetterSignUp.module.css";

const LandingPageNewsLetterSignUp = () => {
  // Context
  const {
    newsLetterEmail,
    setNewsLetterEmail,
    emailSignUpRequestObject,
    addEmailSubscriber,
  } = useContext(AppContext);
  return (
    <div className={classes.container}>
      <h4>Stay up to date with our products and opportunities</h4>
      <div>
        <input
          type="text"
          placeholder="ENTER YOUR EMAIL"
          value={newsLetterEmail}
          onChange={(e) => {
            setNewsLetterEmail(e.target.value);
          }}
        />
        <button
          onClick={addEmailSubscriber}
          disabled={!newsLetterEmail || !newsLetterEmail.includes("@")}
        >
          {emailSignUpRequestObject.isLoading ? "Loading..." : "SUBMIT"}
        </button>

        <span
          className={classes.warning}
          style={
            emailSignUpRequestObject.error
              ? { color: "red" }
              : { color: "white" }
          }
        >
          {capitalize(emailSignUpRequestObject.error) ||
            capitalize(emailSignUpRequestObject.data)}
        </span>
      </div>
    </div>
  );
};

export default LandingPageNewsLetterSignUp;
