import classes from './HeaderText.module.css';

const HeaderText = ({ title, caption, isCentered }) => {
  return (
    <div
      className={classes.container}
      style={isCentered ? { textAlign: 'center' } : { textAlign: 'left' }}
    >
      <p>{caption}</p>
      <h4>{title}</h4>
    </div>
  );
};

export default HeaderText;
