import { useEffect } from "react";
import FAQDropdown from "../../Components/FAQDropdown/FAQDropdown";
import classes from "./MembershipRole.module.css";
import Aos from "aos";
import "aos/dist/aos.css";

const MembershipRole = () => {
  const FAQs = [
    {
      header: "PROGRAM MANAGEMENT",
      content:
        "Support youth from your country/territory to connect with our experiences abroad. You'll work with AIESEC offices overseas and help guide young people who choose to take up an AIESEC exchange experience. Skills you can gain: Sales, customer service, communication, project management",
    },
    {
      header: "MARKETING",
      content:
        "Use your creativity and analytical skills to create promotional campaigns to attract customers to our programs, events, and initiatives. You'll analyze customer insights, brainstorm ideas, and collaborate to make them happen - all to better project our brand, reach, and impact. Skills you can gain: Brand management, data analysis, social media",
    },
    {
      header: "PARTNERSHIPS & BUSINESS DEVELOPMENT",
      content:
        "Work on corporate or non-corporates sales by engaging local companies and organizations to create internships, projects, or events for youth. You'll manage client relationships, learn how organizations work, and help interns settle in your country/territory. Skills you can gain: Sales, customer service, project management",
    },
    {
      header: "FINANCE",
      content:
        "Play a critical role in ensuring our local operational strategies are executed effectively and efficiently. You'll monitor finances, provide investment strategies, ensure we are complying with the law and our internal policies, and make sure we'll still be here in the future. Skills you can gain: Strategic planning, budgeting, resources management",
    },
    {
      header: "HUMAN RESOURCES",
      content:
        "It's only by investing in finding and developing the best in our people that we can grow as an organization. You'll design training and development plans, track performance, and create systems that celebrate achievement as well as recruit new people into your local team. Skills you can gain: communication skills, HR processes management, recruitment",
    },
    {
      header: "SALES OPERATION",
      content:
        "Work on corporate or non-corporates sales by engaging local companies and organizations to create internships, projects, or events for youth. You'll manage client relationships, learn how organizations work, and help interns settle in your country/territory. Skills you can gain: Sales, customer service, project management",
    },
  ];

  // Effects
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <section className={classes.container}>
      <div className={classes.dropdownSection}>
        {FAQs.map((data, i) => {
          return (
            <div key={i} data-aos="fade-down">
              <FAQDropdown header={data.header} content={data.content} />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default MembershipRole;
