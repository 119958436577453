import classes from './Button.module.css';
import { CircularProgress } from '@mui/material';

const Button = ({
  children,
  type,
  disabled,
  onClick,
  loading,
  noRadius,
  subClass,
}) => {
  return (
    <button
      className={`${!subClass ? classes.button : undefined} ${
        type === 'secondary'
          ? classes.secondary
          : type === 'tertiary'
          ? classes.tertiary
          : type === 'nv'
          ? classes.nv
          : classes.primary
      } ${subClass === 'nv' ? classes.nv : undefined}`}
      onClick={onClick}
      disabled={loading || disabled}
      style={noRadius ? { borderRadius: '0px' } : undefined}
    >
      {loading ? <CircularProgress size="1.5rem" color="inherit" /> : children}
    </button>
  );
};

export default Button;
