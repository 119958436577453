import { useEffect } from 'react';
import classes from './GmwWaitlistPage.module.css';
import colorblock from '../../Assets/Images/colorblock.png';
import colortower from '../../Assets/Images/colortower.png';
import gmwblacklogo from '../../Assets/Images/black.svg';
import seki from '../../Assets/Images/Seki.png';
import jamal from '../../Assets/Images/jamal.png';
import gambit from '../../Assets/Images/gambit.png';
import selfie from '../../Assets/Images/selfiecam.png';
import Button from '../../Components/Button/Button';
import { useNavigate } from 'react-router';
import Aos from 'aos';
import 'aos/dist/aos.css';

const GmwWaitlistPage = () => {
  const navigate = useNavigate();

  const ourLCs = [
    {
      name: 'Abeokuta',
    },
    {
      name: 'Abuja',
    },
    {
      name: 'Akure',
    },
    {
      name: 'Benin',
    },
    {
      name: 'Benue',
    },
    {
      name: 'Calabar',
    },
    {
      name: 'Enugu',
    },
    {
      name: 'Ibadan',
    },
    {
      name: 'Ife',
    },
    {
      name: 'Ilorin',
    },
    {
      name: 'Jos',
    },
    {
      name: 'Lagos',
    },
    {
      name: 'Portharcourt',
    },
  ];

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <img src={gmwblacklogo} alt="the gmw logo in black color" />
      </div>
      <div className={classes.heroSection}>
        <div className={classes.heroTextSection}>
          <h4>Get your money game up!</h4>
          <p>
            Let AIESEC in Nigeria guide you through the best ways to up your
            financial knowledge and game with our Global Money Week activities
            and events all over the country!
          </p>
          <div>
            <Button
              onClick={() => {
                navigate('/gmw');
              }}
            >
              Join the Waitlist
            </Button>
          </div>
        </div>
        <div className={classes.heroImage}>
          <div className={classes.colorblock}>
            <img src={colorblock} alt="a block of colors" />
          </div>
          <div className={classes.colorTower}>
            <img src={colortower} alt="a block of colors" />
          </div>
        </div>
      </div>
      <div className={classes.aboutSection}>
        <div className={classes.aboutTextSection}>
          <h4>What is Global money week?</h4>
          <p>
            There’s no sustenance without money, and young people like you need
            all the resources you can get to be sure you make the best financial
            decisions possible in your lifetime.
            <br />
            <br />
            That is why{' '}
            <span className={classes.highLight}>Global Money Week,</span> a
            week-long awareness campaign, exists to ensure you are financially
            aware and get all the skills and behaviors you need to achieve
            ultimate financial well-being at the right standards.
          </p>
        </div>

        <img src={seki} alt="a young lady " />
      </div>
      <div className={classes.whySection}>
        <h4>why you should be a part?</h4>
        <div className={classes.whyImageSection}>
          <div className={classes.partOne}>
            <div className={classes.partImage}>
              <img src={jamal} alt="a young man " />
            </div>
            <div className={classes.partImage}>
              <img src={gambit} alt="a young man " />
            </div>
          </div>
          <div className={classes.partTwo}>
            <img src={selfie} alt="a young lady " />
          </div>
        </div>
        <p>
          Earning a lot of money isn’t the perfect shot at financial freedom.
          Making the best money decisions regarding saving, investing, and
          spending is your shot. This year, Global Money Week highlights (insert
          theme). Be a part of learning (whatever is related to the theme)
        </p>
      </div>
      <div className={classes.waitListSection}>
        <h4>Join the waitlist</h4>
        <p>
          In all 13 local chapters of AIESEC in Nigeria, there are different
          activities and events planned out for young people like you. So, to be
          a part of global money week in AIESEC in Nigeria and get your money
          game up, look out for the activity closest to you and show up!{' '}
        </p>
        <div className={classes.ourLCSection}>
          {ourLCs.map((data, i) => {
            return (
              <div key={i} className={classes.LC} data-aos="fade-down">
                <span>{data.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GmwWaitlistPage;
