import classes from "./LandingPageAboutAIESEC.module.css";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
// Partners
import abc from "../../Assets/Images/abc.png";
import Aelph from "../../Assets/Images/Aleph.png";
import alu from "../../Assets/Images/alu.png";
import cchub from "../../Assets/Images/cchub.svg";
import eHealthAfrica from "../../Assets/Images/ehealthAfrica.svg";
import printivo from "../../Assets/Images/printivo.svg";
import uRecycle from "../../Assets/Images/uRecycle.png";
import yilingPharmaceutical from "../../Assets/Images/yilingPharmaceutical.png";
import { scrollToTheTop } from "../../Utilities/scrollToTop";

const LandingPageAboutAIESEC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // Utils
  const scrollHandler = (e) => {
    e.preventDefault();

    if (location.pathname.startsWith("/#") || location.pathname === "/") {
      const hashVal = e.currentTarget.getAttribute("href").split("/").join("");
      // @ts-ignore
      window.history.pushState(null, null, `${hashVal}`);
      document.querySelector(hashVal).scrollIntoView({
        behavior: "smooth",
      });
    } else {
      navigate.push(e.currentTarget.getAttribute("href"));
    }
  };
  const aboutUserType = [
    {
      title: "I’m a Student",
      section: "student",
      content: "Gain experience volunteering with AIESEC.",
      ctaUrl: "/",
    },
    {
      title: "I’m a Graduate",
      section: "graduate",
      content: "Kickstart your career by interning with AIESEC.",
      ctaUrl: "/",
    },
    {
      title: "I’m an Organization",
      section: "organization",
      content: "Create life changing opportunities with AIESEC",
      ctaUrl: "/",
    },
  ];

  const partners = [
    abc,
    Aelph,
    alu,
    cchub,
    eHealthAfrica,
    printivo,
    uRecycle,
    yilingPharmaceutical,
  ];
  return (
    <div className={classes.container}>
      <div className={classes.svgContainer}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="67"
        height="91"
        viewBox="0 0 67 91"
        fill="none"
      >
        <path
          d="M65.3143 1.51892C45.8033 14.1921 26.3642 29.0193 6.19136 37.9941C-1.61964 41.4691 2.8786 44.5426 8.14952 44.3529C19.0594 43.9602 30.3039 40.1752 41.2862 39.0293C44.4537 38.6987 57.4561 36.1045 49.3635 43.527C43.3028 49.086 12.189 71.8156 29.7199 69.5127C44.597 67.5585 36.4429 77.5015 34.9024 89.8194"
          stroke="#037EF3"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
      </div>
      <div className={classes.aboutContainer}>
        <h6>ABOUT AIESEC</h6>
        <p>
          AIESEC is a global platform for young people to develop their
          leadership potential through international internships and volunteer
          opportunities. We facilitate and promote cultural understanding and
          develop socially responsible, proactive agents of change with a view
          of making a positive impact in the world through our International
          Exchange and Membership Program.
        </p>
      </div>
      <div className={classes.aboutUserType}>
        {aboutUserType.map((data, i) => {
          return (
            <div key={i}>
              <h4>{data.title}</h4>
              <p>{data.content}</p>
              <div>
                <Link
                  to={
                    data.section === "organization"
                      ? "/for-organizations"
                      : `/#${data.section}`
                  }
                  onClick={
                    data.section === "organization"
                      ? scrollToTheTop
                      : scrollHandler
                  }
                >
                  Learn More
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    opacity="0.7"
                    d="M1.5 10.5L11 1M11 1H4.18868M11 1V7.63208"
                    stroke="#037EF3"
                    stroke-width="1.43396"
                  />
                </svg>
                </Link>
               
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="126"
                  height="9"
                  viewBox="0 0 126 9"
                  fill="none"
                >
                  <path
                    d="M1.34009 7.94615C29.8501 7.94615 58.1635 2.32561 86.79 2.11512C98.6623 2.02783 110.426 1.95706 122.28 2.11512C125.491 2.15794 125.349 1.52784 122.136 1.46722C116.433 1.3596 111.19 0.378442 105.363 0.675354C94.6043 1.22357 83.8105 0.831205 73.0402 1.32324C51.0768 2.32664 29.0912 1.95116 7.17113 2.76301"
                    stroke="#037EF3"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
          );
        })}
      </div>
      <div className={classes.ourPartners}>
        <h6>OUR PARTNERS</h6>
        <div className={classes.partnerImages}>
          {partners.map((partnerImage, i) => {
            return (
              <img src={partnerImage} alt="Partner" key={i} loading="lazy" />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LandingPageAboutAIESEC;
