import classes from "./ForOurganizationsOurPartners.module.css";
import Button from "../../Components/Button/Button";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";

// Partners
import abc from "../../Assets/Images/abc.png";
import Aelph from "../../Assets/Images/Aleph.png";
import alu from "../../Assets/Images/alu.png";
import cchub from "../../Assets/Images/cchub.svg";
import eHealthAfrica from "../../Assets/Images/ehealthAfrica.svg";
import printivo from "../../Assets/Images/printivo.svg";
import uRecycle from "../../Assets/Images/uRecycle.png";
import yilingPharmaceutical from "../../Assets/Images/yilingPharmaceutical.png";

import useEmblaCarousel from 'embla-carousel-react'
import ForOrganizationsDrawer from "../ForOrganizationsDrawer/ForOrganizationsDrawer";

const ForOurganizationsOurPartners = () => {

  const [emblaRef] = useEmblaCarousel({ slidesToScroll: 'auto', containScroll: 'trimSnaps', loop: 'true', dragFree: 'true' });

  // Utils
  const partners = [

    { image: abc, name: "ABC Transport" },
    { image: Aelph, name: "Aelph" },
    { image: alu, name: "ALU" },
    { image: cchub, name: "CC Hub" },
    { image: eHealthAfrica, name: "E-HEalth Africa" },
    { image: printivo, name: "Printivo" },
    { image: uRecycle, name: "U-Recycle" },
    { image: yilingPharmaceutical, name: "Yilling Pharmaceutical" },
  ];

  // Effects
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };



  return (
    <section className={classes.outerContainer}>
      <div className={classes.header}>
        <div>
          <h4 data-aos="fade-up">Our partners</h4>
          <p>They have joined us in developing more youth leaders</p>
        </div>
        <div>
          {/* <a href="mailto:nigeria@aiesec.net"> */}
          <Button onClick={showDrawer}>Become a partner </Button>
          {/* </a> */}

          {
            open ? (<ForOrganizationsDrawer open={open} onClose={onClose}/>) : ''
          }

        </div>

      </div>
      <div className={classes.partnerSection}>
        <div className={classes.partnerSectionCarousel}>
          <div className={classes.partnerSectionCarousel__viewport} ref={emblaRef}>
            <div className={classes.partnerSectionCarousel__container}>
              {partners.map((data, i) => {
                return (
                  <div className={classes.partnerSectionCarousel__slide}>

                    <div key={i} className={classes.partner} data-aos="fade-up">
                      <img src={data.image} alt={data.name} loading="lazy" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForOurganizationsOurPartners;
