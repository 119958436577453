import HeaderText from '../../Components/HeaderText/HeaderText';
import classes from './NvHomeVolunteers.module.css';
import testimonial1 from '../../Assets/Images/testimonial1.svg';
import testimonial2 from '../../Assets/Images/testimonial2.svg';
import testimonial3 from '../../Assets/Images/testimonial3.svg';

const NvHomeVolunteers = () => {
  // States
  const testimonials = [
    {
      name: 'Bright Uzosike',
      caption:
        'There is more happiness in giving than there is in receiving. No matter how small you give to those in need, it goes a long way to put a smile on their faces.',
      image: testimonial1,
    },

    {
      name: 'Deborah',
      caption:
        'Working and relating to the children. It was always exciting to see them work on projects independently and come out with excellent results.',
      image: testimonial2,
    },

    {
      name: 'Kashimana',
      caption:
        'My experience was one of the best I have ever had. From 100% support from the team to learning about a whole new diverse culture. ',
      image: testimonial3,
    },
  ];

  return (
    <div className={classes.container}>
      <HeaderText
        title={
          <>
            What they had
            <br />
            to say!
          </>
        }
        caption="The Volunteers"
        isCentered
      />

      <div className={classes.testimonoalSection}>
        {testimonials.map((data, i) => {
          return (
            <div className={classes.testmonial} key={i}>
              <img src={data.image} alt={data.name} />
              <div>{data.name}</div>
              <div>{data.caption}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NvHomeVolunteers;
