import Layout from '../../Components/Layout/Layout';
import BlogArticleThree from '../../Containers/BlogArticleThree/BlogArticleThree';


const BlogThree = () => {
    return (
        <Layout>
            <BlogArticleThree />
        </Layout>
    );
}

export default BlogThree;