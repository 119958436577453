import axios from "axios";
import { useState } from "react";
import { createContext } from "react";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  // States
  const [newsLetterEmail, setNewsLetterEmail] = useState("");
  const [emailSignUpRequestObject, setEmailSignupRequestObject] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  //   Requests
  const addEmailSubscriber = () => {
    setEmailSignupRequestObject({
      isLoading: true,
      data: null,
      error: null,
    });
    axios
      .post(`https://ainbackend.fly.dev/api/submit_email`, {
        email: newsLetterEmail,
      })
      .then((res) => {
        setEmailSignupRequestObject({
          isLoading: false,
          data: res.data.detail,
          error: null,
        });
      })
      .catch((err) => {
        setEmailSignupRequestObject({
          isLoading: false,
          data: null,
          error: err.response.data.detail,
        });
      });
  };
  return (
    <AppContext.Provider
      value={{
        newsLetterEmail,
        setNewsLetterEmail,
        emailSignUpRequestObject,
        addEmailSubscriber,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
