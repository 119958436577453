
const ShareButton = ({ text, linktext }) => {
    const sharePlatforms = [
        {
            platform: "linkedin",
            url: ` http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(linktext)}&title=${encodeURIComponent(text)}`,
            icon: <i className="fa-brands fa-linkedin"></i>
        },
        {
            platform: "twitter",
            url: `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}${encodeURIComponent(linktext)}`,
            icon: <i className="fa-brands fa-twitter"></i>,
        },
    ];

    const handleShare = (url) => {
        window.open(url);
    };


    return (
        <div className="flex">
            {sharePlatforms.map((platform) => (
                <button
                    //   className="text-2xl mx-4 hover:text-slate-600 ease-in transition-all"
                    key={platform.platform}
                    onClick={() => handleShare(platform.url)}
                >
                    {platform.icon}
                </button>
            ))}

            <div
                className="fb-share-button text-2xl mx-4"
                data-href="https://birthday.bestastrologyblogs.com"
                data-layout=""
                data-size=""
            >
                <a
                    rel="noreferrer"
                    target="_blank"
                    href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2F12zodiacsigns.co%2F&amp;src=sdkpreparse`}
                    className="fb-xfbml-parse-ignore ease hover:text-slate-600 ease-in transition-all"
                >
                </a>
            </div>
        </div>
    );
};

export default ShareButton;
