
import classes from "./LocalCommitteePagesOurProjects.module.css";
import AiALogo from "../../Assets/Images/AiALogo.svg";

const LocalCommitteePagesOurProjects = ({LCprojects}) => {
  const projects = LCprojects?.projects
  return (
    <section className={classes.container}>
      <h4>OUR PROJECTS</h4>
      {
        projects && (
          <div className={classes.projectsContainer}>
        {projects.map((data, i) => {
          return (
            <a target="_blank" rel="noopener noreferrer" href={data.projectLink} key={i} className={classes.project}>
              <img
                src={data.projectImageUrl || AiALogo}
                alt={`${data.title} ${i + 1}`}
                loading="lazy"
              />
              <h6>{`${data.title} `}</h6>
              <span>{data.tags[0]}</span>
            </a>
          );
        })}
      </div>
        )
      }
    </section>
  );
};

export default LocalCommitteePagesOurProjects;
