import Layout from "../../Components/Layout/Layout";
import LandingPageAboutAIESEC from "../../Containers/LandingPageAboutAIESEC/LandingPageAboutAIESEC";
import LandingPageHeroSection from "../../Containers/LandingPageHeroSection/LandingPageHeroSection";
import LandingPageNewContent from "../../Containers/LandingPageNewContent/LandingPageNewContent";
import LandingPageNewsLetterSignUp from "../../Containers/LandingPageNewsLetterSignUp/LandingPageNewsLetterSignUp";
import LandingPageProducts from "../../Containers/LandingPageProducts/LandingPageProducts";
import LandingPageTestimonials from "../../Containers/LandingPageTestimonials/LandingPageTestimonials";

const LandingPage = () => {

  return (
    <Layout>
      <LandingPageHeroSection />
      <LandingPageAboutAIESEC />
      <LandingPageProducts />
      <LandingPageTestimonials />
      <LandingPageNewContent />
      <LandingPageNewsLetterSignUp />
    </Layout>
  );
};

export default LandingPage;
