import { useParams } from 'react-router-dom';
import Layout from '../../Components/Layout/Layout';
import LocalCommitteePagesHeader from '../../Containers/LocalCommitteePagesHeader/LocalCommitteePagesHeader';
import LandingPageNewsLetterSignUp from '../../Containers/LandingPageNewsLetterSignUp/LandingPageNewsLetterSignUp';
import LocalCommitteeEB from '../../Containers/LocalCommitteeEB/LocalCommitteeEB';
import LocalCommitteeOurGallery from '../../Containers/LocalCommitteeOurGallery/LocalCommitteeOurGallery';
import LocalCommitteePagesOurProjects from '../../Containers/LocalCommitteePagesOurProjects/LocalCommitteePagesOurProjects';
import { useState, useEffect } from 'react';
import { getLocalCommittePage } from '../../Utilities/getLocalCommittePage';
import LocalCommitteePlaceholder from '../../Containers/LocalCommitteePlaceholder/LocalCommitteePlaceholder';

const LocalCommitteePages = () => {
  const { localCommittee } = useParams();
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await getLocalCommittePage(localCommittee);
        setPageData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [localCommittee]);

  const isDataLoaded = pageData && Object.keys(pageData).length > 0;

  return (
    <Layout>
      {loading ? null : isDataLoaded ? (
        <>
          <LocalCommitteePagesHeader localCommittee={pageData} />
          <LocalCommitteePagesOurProjects LCprojects={pageData} />
          <LocalCommitteeEB localCommitteeEB={pageData} />
          <LocalCommitteeOurGallery lcPhotos={pageData} />
        </>
      ) : (
        <LocalCommitteePlaceholder />
      )}
      <LandingPageNewsLetterSignUp />
    </Layout>
  );
};

export default LocalCommitteePages;
