// import { useEffect } from 'react';
import classes from './LocalCommitteeEB.module.css';
import EB from '../../Assets/Images/EB.svg';
// import Aos from 'aos';
// import 'aos/dist/aos.css';

const LocalCommitteeEB = ({ localCommitteeEB }) => {
  console.log(localCommitteeEB);
  const executiveBoard = localCommitteeEB?.executiveBoard || '';
  //  [
  //   {
  //     name: "Jacob Jones",
  //     role: "Medical Assistant",
  //     image: EB,
  //   },
  //   {
  //     name: "Jacob Jones",
  //     role: "Medical Assistant",
  //     image: EB,
  //   },
  //   {
  //     name: "Jacob Jones",
  //     role: "Medical Assistant",
  //     image: EB,
  //   },
  //   {
  //     name: "Jacob Jones",
  //     role: "Medical Assistant",
  //     image: EB,
  //   },
  //   {
  //     name: "Jacob Jones",
  //     role: "Medical Assistant",
  //     image: EB,
  //   },
  //   {
  //     name: "Jacob Jones",
  //     role: "Medical Assistant",
  //     image: EB,
  //   },
  // ];

  // Effects
  // useEffect(() => {
  //   Aos.init({ duration: 1000 });
  // }, []);

  return (
    <section className={classes.container}>
      <h4>OUR EXECUTIVE BOARD</h4>

      {executiveBoard && (
        <div className={classes.ebInfoSection}>
          {executiveBoard.map((data, i) => {
            return (
              <div
                key={i}
                className={classes.ebPerson}
                // data-aos="fade-up"
                loading="lazy"
              >
                <img src={data.profileImageUrl || EB} alt={data.name} />
                <div>
                  <h6>{data.name}</h6>
                  <p>{data.position}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default LocalCommitteeEB;
