import { useEffect } from "react";
import classes from "./AboutUsEB.module.css";
import Aos from "aos";
import "aos/dist/aos.css";

// Old Links
// let Blessing = 'https://i.ibb.co/7JfyhTW/Blessing-MCP.jpg';
// let Azeez = 'https://i.ibb.co/3f3VFgh/Azeez-MCVP-ICX.jpg';
// let Daniel = 'https://i.ibb.co/s94TXd4/Daniel-MCVP-F-L.jpg';
// let Miracle = 'https://i.ibb.co/6rr6fKb/Miracle-MCVP-OGX.jpg';
// let Mercy = 'https://i.ibb.co/NNGbW9r/Mercy-MCVP-BD-Ew-A.jpg';
// let Kunle = 'https://i.ibb.co/kGDyWwC/Kunle-MCVP-MX-OD.jpg';

let Blessing = 'https://res.cloudinary.com/skycoder/image/upload/v1708609506/Blessing_Ekong_qgs6qt.jpg';
let Azeez = 'https://res.cloudinary.com/skycoder/image/upload/v1708609505/Azeez_Bello_fe0yyv.jpg';
let Daniel = 'https://res.cloudinary.com/skycoder/image/upload/v1708609505/Daniel_Opadiran_kd6obl.jpg';
let Miracle = 'https://res.cloudinary.com/skycoder/image/upload/v1708609504/Miracle_Ihejimba_kzmc61.jpg';
let Mercy = 'https://res.cloudinary.com/skycoder/image/upload/v1708609505/Mercy_Jacob_wtfesh.jpg';
let Kunle = 'https://res.cloudinary.com/skycoder/image/upload/v1708609505/Olakunle_Soji-Oke_skpn26.jpg';
let Ola = 'https://res.cloudinary.com/skycoder/image/upload/v1708609504/Olaotan_Oyenuga_lsz96o.jpg'


const AboutUsEB = () => {
  const executiveBoard = [
    {
      name: "Blessing Ekong",
      role: "Country Director",
      image: Blessing,
    },
    {
      name: "Azeez Bello",
      role: "Programs Manager",
      image: Azeez,
    },
    {
      name: "Mercy Jacob",
      role: "Partnerships Manager",
      image: Mercy,
    },
    {
      name: "Daniel Opadiran",
      role: "Finance Manager",
      image: Daniel,
    },
    {
      name: "Miracle Ihejimba",
      role: "Product Operations Manager",
      image: Miracle,
    }, 
    {
      name: "Olaotan Oyenuga",
      role: "Marketing Manager",
      image: Ola,
    },
    {
      name: "Olakunle Soji-Oke",
      role: "Human Resource Manager",
      image: Kunle,
    },
   
  ];

  // Effects
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <section className={classes.container}>
      <div className={classes.header} data-aos="fade-up">
        We are led by the{" "}
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="11"
            viewBox="0 0 162 11"
            fill="none"
          >
            <path
              d="M1 10C37.7712 10 74.2887 3.09566 111.21 2.83709C126.523 2.72985 141.695 2.64292 156.984 2.83709C161.126 2.88969 160.943 2.11566 156.798 2.04119C149.442 1.90899 142.68 0.703723 135.165 1.06845C121.289 1.74189 107.367 1.2599 93.4762 1.86433C65.1485 3.09692 36.7924 2.63568 8.52067 3.63296"
              stroke="#037EF3"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
          <span>right people</span>
        </span>{" "}
        with the right skills
      </div>
      <div className={classes.ebInfoSection}>
        {executiveBoard.map((data, i) => {
          return (
            <div key={i} className={classes.ebPerson} data-aos="fade-up">
              <img src={data.image} alt={data.name} loading="lazy" />
              <div>
                <h6>{data.role}</h6>
                <p>{data.name}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default AboutUsEB;
