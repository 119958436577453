import classes from "./ForOrganizationsHeroSection.module.css";
import Button from "../../Components/Button/Button";
import { useState } from "react";
import ForOrganizationsHero1 from "../../Assets/Images/ForOrganizationsHero1.svg";
import ForOrganizationsHero2 from "../../Assets/Images/ForOrganizationsHero2.svg";
import ForOrganizationsHero3 from "../../Assets/Images/ForOrganizationsHero3.svg";
import ForOrganizationsHero4 from "../../Assets/Images/ForOrganizationsHero4.svg";
import ForOrganizationsHero5 from "../../Assets/Images/ForOrganizationsHero5.svg";
import ForOrganizationsHero6 from "../../Assets/Images/ForOrganizationsHero6.svg";
import ForOrganizationsDrawer from "../ForOrganizationsDrawer/ForOrganizationsDrawer";

const ForOrganizationsHeroSection = () => {
  // Utils

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };


  return (
    <section className={classes.container}>
      <div className={classes.imgbg}><img src={ForOrganizationsHero1} alt="For Organizations Hero Illustration 1" /></div>
      <div className={classes.imgbg}><img src={ForOrganizationsHero2} alt="For Organizations Hero Illustration 2" /></div>
      <div className={classes.imgbg}><img src={ForOrganizationsHero3} alt="For Organizations Hero Illustration 3" /></div>
      <div className={classes.imgbg}><img src={ForOrganizationsHero4} alt="For Organizations Hero Illustration 4" /></div>
      <div className={classes.imgbg}><img src={ForOrganizationsHero5} alt="For Organizations Hero Illustration 5" /></div>
      <div className={classes.imgbg}><img src={ForOrganizationsHero6} alt="For Organizations Hero Illustration 6" /></div>
      <h4>

        <span>
          <span>Contribute</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="12"
            viewBox="0 0 165 12"
            fill="none"
          >
            <path
              d="M1 11C38.5664 11 75.8735 3.59409 113.593 3.31674C129.237 3.20171 144.737 3.10847 160.357 3.31674C164.588 3.37316 164.401 2.5429 160.167 2.46302C152.652 2.32122 145.744 1.02839 138.066 1.41962C123.89 2.14198 109.667 1.62498 95.4759 2.27331C66.5357 3.59544 37.5664 3.10069 8.6833 4.17042"
              stroke="#037EF3"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </span>{" "}
        to our mission to<br />{" "}
        <span>
          <span>develop more youth leaders</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="12"
            viewBox="0 0 165 12"
            fill="none"
          >
            <path
              d="M1 11C38.5664 11 75.8735 3.59409 113.593 3.31674C129.237 3.20171 144.737 3.10847 160.357 3.31674C164.588 3.37316 164.401 2.5429 160.167 2.46302C152.652 2.32122 145.744 1.02839 138.066 1.41962C123.89 2.14198 109.667 1.62498 95.4759 2.27331C66.5357 3.59544 37.5664 3.10069 8.6833 4.17042"
              stroke="#037EF3"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </span>{" "}
        around the world.
      </h4>
      {/* <p>
        Are you a company, organization or business looking to engage and be
        involved in youth growth and development? AIESEC in Nigeria is your best
        bet. Join forces with AIESEC in Nigeria and get access to a community of
        young people with phenomenal minds and talents from all over the
        country.
      </p> */}
      <div>
        {/* <a href="mailto:nigeria@aiesec.net"> */}
          <Button onClick={showDrawer}>Get Started</Button>
        {/* </a> */}

        {
            open ? (<ForOrganizationsDrawer open={open} onClose={onClose}/>) : ''
          }
        
      </div>

    </section>
  );
};

export default ForOrganizationsHeroSection;
