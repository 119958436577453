import classes from './NvStatesForm.module.css';
import modalBody from '../../Assets/Images/modalBody.svg';

const ModalBody = () => {
  return (
    <div className={classes.modalContainer}>
      <div>
        <img src={modalBody} alt="AIESEC" />
      </div>

      <div>
        <h4>Successful</h4>
        <p>Keep an eye on your mail address for more updates</p>
      </div>
    </div>
  );
};

export default ModalBody;
