import React from "react";
import AIESECMember from "../../Assets/Images/AIESECMember.svg";
import classes from "./FormContainer.module.css";
// import Button from "../../Components/Button/Button";
// import axios from "axios";
// import toast from "react-hot-toast";
// import { Alert } from "@mui/material";
// import { capitalize } from "../../HelperFunctions/capitalize";
// import { useEffect } from "react";

const FormContainer = () => {
  // const [submitForm, setSubmitForm] = useState({
  //   isLoading: false,
  //   data: null,
  //   error: null,
  // });

  // const formRef = React.useRef();

  // const cities = [
  //   "Abeokuta",
  //   "Abuja",
  //   "Akure",
  //   "Benin",
  //   "Benue",
  //   "Calabar",
  //   "Enugu",
  //   "Ibadan",
  //   "Ife",
  //   "Ilorin",
  //   "Jos",
  //   "Lagos",
  //   "Port-Harcourt",
  //   "Others",
  // ];
  // const universities = {
  //   Abeokuta: [
  //     "Federal university of Agriculture Abeokuta",
  //     "Olabisi Onabanjo University",
  //     "Moshood Abiola Polytechnic Abeokuta",
  //     "Federal College of Education Osiele",
  //   ],
  //   Akure: ["Federal University of Technology, Akure"],
  //   Abuja: ["University of Abuja", "Bingham university", "Nile University"],
  //   Benin: ["University of Benin", "Benson Idahosa University"],
  //   Benue: ["Benue State University", "Joseph Sarwuan Tarka University"],
  //   Calabar: [
  //     "University of Calabar",
  //     "University of Cross River",
  //     "Arthur Jarvis University",
  //     "College of Health Technology",
  //     "School of Nursing",
  //   ],
  //   Enugu: [
  //     "University of Nigeria, Nsukka",
  //     "University of Nigeria, Enugu Campus (UNEC)",
  //     "Enugu State University of Science and Technology",
  //     "Federal College of Dental Technology and Therapy Enugu",
  //   ],
  //   Ife: ["Obafemi Awolowo University", "Oduduwa University"],
  //   Ibadan: [
  //     "University of Ibadan",
  //     "Lead City University",
  //     "McPherson University",
  //     "Dominican university",
  //   ],
  //   Ilorin: [
  //     "University of Ilorin",
  //     "Kwara State University",
  //     "Kwara State Polytechnic",
  //     "Al-Hikmah University",
  //     "Landmark University",
  //   ],
  //   Jos: [
  //     "University of Jos",
  //     "National Film Institute Jos",
  //     "ATBU Bauchi",
  //     "Plateau State University",
  //   ],
  //   Lagos: [
  //     "University of Lagos",
  //     "Lagos State University",
  //     "Yaba College of Technology",
  //   ],
  //   "Port-Harcourt": [
  //     "University of Port Harcourt",
  //     "Ignatius Ajuru University of Education",
  //     "Rivers State University",
  //   ],
  //   Others: [],
  // };

  // const [formData, setFormData] = React.useState({
  //   first_name: "",
  //   last_name: "",
  //   phone: "",
  //   email: "",
  //   dob: "",
  //   academic_situation: "student",
  //   residence: "",
  //   school_location: cities[0],
  //   other_names: "",
  //   name_of_school: "",
  //   course: "",
  //   level: "",
  //   aiesec_participation: "yes",
  //   event_participated: "",
  //   motivation: "",
  //   interest: "customer_experience",
  //   skills: "",
  //   hear_about_recruitment: "",
  // });

  // useEffect(() => {
  //   if (formData.school_location) {
  //     setFormData((prevState) => {
  //       return {
  //         ...prevState,
  //         name_of_school: universities[formData?.school_location][0],
  //       };
  //     });
  //   }

  //   if (formData.academic_situation === "graduate") {
  //     setFormData((prevState) => {
  //       return { ...prevState, name_of_school: "" };
  //     });
  //   }

  //   // eslint-disable-next-line
  // }, [formData?.school_location, formData.academic_situation]);

  // const [formErrors] = React.useState({
  //   first_nameError: "",
  //   last_nameError: "",
  //   phoneError: "",
  //   emailError: "",
  //   dobError: "",
  //   academic_situationError: "",
  //   residenceError: "",
  //   school_locationError: "",
  //   name_of_schoolError: "",
  //   courseError: "",
  //   levelError: "",
  //   aiesec_participationError: "",
  //   event_participatedError: "",
  //   motivationError: "",
  //   interestError: "",
  //   skillsError: "",
  //   hear_about_recruitmentError: "",
  // });

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [e.target.name]: e.target.value,
  //   }));
  // };

  // const handleSubmit = () => {
  //   setSubmitForm({ isLoading: true, data: null, error: null });
  //   if (!formRef.current.checkValidity()) {
  //     toast.error("You have empty field(s)");
  //     return;
  //   }
  //   axios
  //     .post("https://ainbackend.fly.dev/api/recruitment", formData)
  //     .then((res) => {
  //       toast.success(res.data.detail);
  //       setSubmitForm({ isLoading: false, data: res.data.detail, error: null });
  //       setFormData({
  //         first_name: "",
  //         last_name: "",
  //         phone: "",
  //         email: "",
  //         dob: "",
  //         academic_situation: "student",
  //         residence: "",
  //         school_location: cities[0],
  //         other_names: "",
  //         name_of_school: universities[cities[0]],
  //         course: "",
  //         level: "",
  //         aiesec_participation: "yes",
  //         event_participated: "",
  //         motivation: "",
  //         interest: "customer_experience",
  //         skills: "",
  //         hear_about_recruitment: "",
  //       });
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.detail);
  //       setSubmitForm({
  //         isLoading: false,
  //         data: null,
  //         error: err.response.data.detail,
  //       });
  //     });
  // };

  return (
    <div className={classes.innerContainer}>
      <div className={classes.roles}>
        <div className={classes.topImage}>
          <img src={AIESECMember} alt="AIESEC member logo" loading="lazy" />
        </div>
        <div className={classes.rolesText}>
          <h4>AIESEC IN NIGERIA IS NO LONGER RECRUITING.</h4>
          {/* <p>
            Join one of our local teams and develop yourself through practical
            experiences in the world’s largest youth-run and led organisation
          </p> */}
          <p>
            While this might be a bummer, there's so much more to explore on our homepage. You'll be amazed by the endless opportunities to lead and create an impact with AIESEC.
          </p>
          <p>
            That's not all, we've got so much to offer! Stay connected with us on our social media platforms and never miss out on exciting updates, events, and engagement opportunities with AIESEC.
          </p>
          <p>
            We can't wait to connect with you!
          </p>
        </div>
      </div>
      {
        // <div className={classes.boxForm}>
        //   {submitForm.data ? (
        //     <div className={classes.done}>
        //       <Alert severity="success">{capitalize(submitForm?.data)}</Alert>
        //       <Button
        //         onClick={() => {
        //           setSubmitForm((prevState) => {
        //             return { ...prevState, data: null };
        //           });
        //         }}
        //       >
        //         Submit another application
        //       </Button>{" "}
        //     </div>
        //   ) : (
        //     <form
        //       id="memberDetails"
        //       ref={formRef}
        //       onSubmit={(e) => {
        //         e.preventDefault();
        //       }}
        //     >
        //       <div className={classes.error}>
        //         {submitForm?.error && (
        //           <Alert severity="error">
        //             {capitalize(submitForm?.error)}
        //           </Alert>
        //         )}
        //       </div>

        //       <div className={classes.One}>
        //         <div
        //           className={classes.oneChild}
        //           error={!!formErrors.first_nameError}
        //         >
        //           <label for="fname">First name</label>
        //           <input
        //             type="text"
        //             id="first_name"
        //             name="first_name"
        //             placeholder="John"
        //             value={formData.first_name}
        //             onChange={handleChange}
        //             required
        //           />
        //         </div>
        //         <div
        //           className={classes.oneChild}
        //           error={!!formErrors.last_nameError}
        //         >
        //           <label for="lname">Last name</label>
        //           <input
        //             type="text"
        //             id="last_name"
        //             name="last_name"
        //             placeholder="Doe"
        //             value={formData.last_name}
        //             onChange={handleChange}
        //             required
        //           />
        //         </div>
        //       </div>
        //       <div className={classes.oneChild} error={!!formErrors.dobError}>
        //         <label for="dbirth">Date of Birth</label>
        //         <input
        //           type="date"
        //           id="dob"
        //           name="dob"
        //           placeholder="dd/mm/yyyy"
        //           value={formData.dob}
        //           onChange={handleChange}
        //           required
        //         />
        //       </div>
        //       <div className={classes.oneChild} error={!!formErrors.emailError}>
        //         <label for="email">Email Address</label>
        //         <input
        //           type="email"
        //           id="email"
        //           name="email"
        //           placeholder="johndoe@gmail.com"
        //           value={formData.email}
        //           onChange={handleChange}
        //           required
        //         />
        //       </div>
        //       <div className={classes.oneChild} error={!!formErrors.phoneError}>
        //         <label for="phone">Preferred Phone Number</label>
        //         <input
        //           type="text"
        //           id="phone"
        //           name="phone"
        //           placeholder="080 2348 9671"
        //           value={formData.phone}
        //           onChange={handleChange}
        //           required
        //         />
        //       </div>
        //       <div
        //         className={classes.oneChild}
        //         error={!!formErrors.academic_situationError}
        //       >
        //         <label for="acad">Academic Situation</label>
        //         <select
        //           id="academic_situation"
        //           name="academic_situation"
        //           value={formData.academic_situation}
        //           onChange={handleChange}
        //         >
        //           <option value="student">Student</option>
        //           <option value="graduate">Graduate</option>
        //         </select>
        //       </div>
        //       <div
        //         className={classes.oneChild}
        //         error={!!formErrors.residenceError}
        //         style={{
        //           display:
        //             formData.academic_situation === "student"
        //               ? "none"
        //               : "block",
        //         }}
        //       >
        //         <label for="residence">
        //           Not a Student? Where do you reside?
        //         </label>
        //         <input
        //           type="text"
        //           id="residence"
        //           name="residence"
        //           placeholder="Lafia"
        //           value={formData.residence}
        //           onChange={handleChange}
        //         />
        //       </div>
        //       <div
        //         className={classes.oneChild}
        //         error={!!formErrors.school_locationError}
        //         style={{
        //           display:
        //             formData.academic_situation === "graduate"
        //               ? "none"
        //               : "block",
        //         }}
        //       >
        //         <label for="location">Location of academic institution</label>
        //         <select
        //           id="school_location"
        //           name="school_location"
        //           value={formData.school_location}
        //           onChange={handleChange}
        //         >
        //           {cities.map((city) => (
        //             <option value={city}>{city}</option>
        //           ))}
        //         </select>
        //       </div>
        //       <div
        //         className={classes.oneChild}
        //         error={!!formErrors.school_locationError}
        //         style={{
        //           display:
        //             formData.academic_situation === "graduate"
        //               ? "none"
        //               : formData.school_location === "Others"
        //               ? "none"
        //               : "block",
        //         }}
        //       >
        //         <label for="location">Name of academic institution</label>
        //         <select
        //           id="name_of_school"
        //           name="name_of_school"
        //           value={formData.name_of_school}
        //           onChange={handleChange}
        //         >
        //           {formData?.school_location &&
        //             universities[formData.school_location].map((city) => (
        //               <option value={city}>{city}</option>
        //             ))}
        //         </select>
        //       </div>
        //       <div
        //         className={classes.oneChild}
        //         error={!!formErrors.name_of_schoolError}
        //         style={{
        //           display:
        //             formData.academic_situation === "graduate"
        //               ? "none"
        //               : formData.school_location === "Others"
        //               ? "block"
        //               : "none",
        //         }}
        //       >
        //         <label for="institution">
        //           Can't find your State or University here? Please tell us.
        //         </label>
        //         <input
        //           type="text"
        //           id="other_names"
        //           name="other_names"
        //           placeholder="Covenant University, Ogun state"
        //           value={formData.other_names}
        //           onChange={handleChange}
        //         />
        //       </div>
        //       <div
        //         className={classes.oneChild}
        //         error={!!formErrors.courseError}
        //         style={{
        //           display:
        //             formData.academic_situation === "graduate"
        //               ? "none"
        //               : "block",
        //         }}
        //       >
        //         <label for="course">Course of Study</label>
        //         <input
        //           type="text"
        //           id="course"
        //           name="course"
        //           placeholder="Medicine"
        //           value={formData.course}
        //           onChange={handleChange}
        //         />
        //       </div>
        //       <div
        //         className={classes.oneChild}
        //         error={!!formErrors.levelError}
        //         style={{
        //           display:
        //             formData.academic_situation === "graduate"
        //               ? "none"
        //               : "block",
        //         }}
        //       >
        //         <label for="level">Level of Study</label>
        //         <input
        //           type="text"
        //           id="level"
        //           name="level"
        //           placeholder="Final year"
        //           value={formData.level}
        //           onChange={handleChange}
        //         />
        //       </div>
        //       <div className={classes.oneChild} error={!!formErrors.eventError}>
        //         <label for="event">
        //           Have you participated in any AIESEC event(s) before?
        //         </label>
        //         <select
        //           id="aiesec_participation"
        //           name="aiesec_participation"
        //           value={formData.aiesec_participation}
        //           onChange={handleChange}
        //           required
        //         >
        //           <option value="yes">Yes</option>
        //           <option value="no">No</option>
        //         </select>
        //       </div>
        //       <div
        //         className={classes.oneChild}
        //         error={!!formErrors.event_partcipatedError}
        //       >
        //         <label for="aiesec">If yes, which AIESEC event(s)?</label>
        //         <input
        //           type="text"
        //           id="event_participated"
        //           name="event_participated"
        //           placeholder=""
        //           value={formData.event_participated}
        //           onChange={handleChange}
        //         />
        //       </div>
        //       <div
        //         className={classes.oneChild}
        //         error={!!formErrors.motivationError}
        //       >
        //         <label for="motivation">
        //           What is your motivation for joining AIESEC?
        //         </label>
        //         <input
        //           type="text"
        //           id="motivation"
        //           name="motivation"
        //           placeholder=""
        //           value={formData.motivation}
        //           onChange={handleChange}
        //           required
        //         />
        //       </div>
        //       <div
        //         className={classes.oneChild}
        //         error={!!formErrors.interestError}
        //       >
        //         <label for="interest">
        //           Which of these are you interested in?
        //         </label>
        //         <select
        //           id="interest"
        //           name="interest"
        //           value={formData.interest}
        //           onChange={handleChange}
        //           required
        //         >
        //           <option value="marketing">Brand and Marketing</option>
        //           <option value="sales">Sales Operations</option>
        //           <option value="partnerships_and_business">
        //             Partnerships and Business Development
        //           </option>
        //           <option value="program_management">Program Management</option>
        //           <option value="human_resources">Human Resources</option>
        //           <option value="finance">Finance</option>
        //         </select>
        //       </div>
        //       <div
        //         className={classes.oneChild}
        //         error={!!formErrors.skillsError}
        //       >
        //         <label for="skills">
        //           Do you have any specific skills you would like us to know
        //           about?
        //         </label>
        //         <input
        //           type="text"
        //           id="skills"
        //           name="skills"
        //           placeholder="Photography"
        //           value={formData.skills}
        //           onChange={handleChange}
        //           required
        //         />
        //       </div>
        //       <div
        //         className={classes.oneChild}
        //         error={!!formErrors.hear_about_recruitmentError}
        //       >
        //         <label for="channel">How did you hear about recruitment?</label>
        //         <input
        //           type="text"
        //           id="hear_about_recruitment"
        //           name="hear_about_recruitment"
        //           placeholder="Instagram"
        //           value={formData.hear_about_recruitment}
        //           onChange={handleChange}
        //           required
        //         />
        //       </div>
        //       <Button
        //         disabled={submitForm?.isLoading}
        //         onClick={() => handleSubmit()}
        //         loading={submitForm?.isLoading}
        //       >
        //         {" "}
        //         Send Application{" "}
        //       </Button>
        //     </form>
        //   )}
        // </div>
      }
    </div>
  );
};

export default FormContainer;
