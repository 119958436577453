import classes from './GmwShowCasePage.module.css';
import dolly from '../../Assets/Images/gmwImage13.png';
import white from '../../Assets/Images/gmwImage14.png';
import temmy from '../../Assets/Images/gmwImage15.png';
import sportie from '../../Assets/Images/gmwImage16.png';
import fade from '../../Assets/Images/gmwImage17.png';

const GmwShowCasePage = () => {
  return (
    <div className={classes.container}>
      <div className={classes.showcaseContainer}>
        <div className={classes.showcaseText}>
          <h4>AIESEC in Nigeria’s GMW: LC SHOWCASE</h4>
          <p>
            This year, AIESEC in Nigeria participated in the GMW 2024 through
            the various local chapters we have all over the country. From joint
            spaces and activities to webinars and physical events, the 13  local
            chapters of AIESEC in Nigeria showed up and out to teach young
            people like you how to protect their money and secure their future.
          </p>
        </div>
        <div className={classes.showcaseImageSection}>
          <div className={classes.showcaseOne}>
            <div>
              <img src={dolly} alt="a young lady speaking" />
            </div>
            <h5>X Space</h5>
            <p>
              Four AIESEC local chapters, Akure, Benin, Ibadan and Lagos,
              gathered four top speakers, including one person from Cowrywise
              and Valora, to talk about what it takes to reach financial freedom
              as a Gen Z. Abuja also had a “Money Talks.” X Space
            </p>
          </div>
          <div className={classes.showcaseOne}>
            <div>
              <img src={white} alt="a young lady speaking" />
            </div>
            <h5>Webinars</h5>
            <p>
              Different LCs had webinars for young people all over the country
              to learn how to get their money game up. Port-Harcourt. Abeokuta
              and Ife also had virtual finance symposiums.
            </p>
          </div>
          <div className={classes.showcaseOne}>
            <div>
              <img src={temmy} alt="a young lady speaking" />
            </div>
            <h5>Radio Visits</h5>
            <p>
              Abuja and Port-Harcourt took global money to wider ears through
              sessions on financial knowledge on their university radio
              stations.
            </p>
          </div>
        </div>
        <div className={classes.showcaseImage}>
          <div className={classes.showcaseTwo}>
            <div>
              <img src={sportie} alt="a young lady speaking" />
            </div>
            <h5>School rallies and visits</h5>
            <p>
              What better way to get young people’s attention to show up than to
              show them why? Calabar, Enugu, and Jos went around their schools
              to build awareness of GMW. These local chapters also visited
              secondary schools to get financial knowledge to teenagers too!
            </p>
          </div>
          <div className={classes.showcaseTwo}>
            <div>
              <img src={fade} alt="a young lady speaking" />
            </div>
            <h5>Physical events</h5>
            <p>
              To wrap it up, Akure had a financial event to teach young people
              how and why they should save and avoid impulse buying. Ibadan also
              had a financial summit for youths. Jos, Benue and Port-Harcourt
              had financial symposiums centred around young people making better
              money decisions.
            </p>
          </div>
        </div>
      </div>
      <div className={classes.NextSection}>
        <div className={classes.NextTextSection}>
          <h4>What's Next?</h4>
          <p>
            Global Money Week in AIESEC in Nigeria this year was an enlightening
            one for everyone who attended. We have more fun and enlightening
            events and opportunities coming up this year. Explore our website to
            stay updated. Keep an eye out for more on our socials!
          </p>
        </div>
        <div className={classes.ExploreButton}>
          <a href="/gmw/2025">Explore Website</a>
        </div>
      </div>
    </div>
  );
};

export default GmwShowCasePage;
