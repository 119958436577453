import PagesContainer from "../../Components/PagesContainer/PagesContainer";
import AIESECer from "../../Assets/Images/AIESECer.png";
import AIESECMemberHeroImage from "../../Assets/Images/AIESECMemberHeroImage.png";

const Membership = () => {
  // Utilities
  const reasons = [
    {
      title: "Gain Cross-cultural Experience",
      description: "Create life changing opportunities with AIESEC",
    },
    {
      title: "Gain Cross-cultural Experience",
      description: "Create life changing opportunities with AIESEC",
    },
    {
      title: "Gain Cross-cultural Experience",
      description: "Create life changing opportunities with AIESEC",
    },
  ];

  const testimonials = [
    {
      testimonial:
        "Going beyond what you know opens up new worlds for you. Find your values, explore your passions, and become more self-aware.",
      image: AIESECer,
      localCommittee: "AIESEC IN JOS",
      name: "Ezimorah Tobenna",
    },
    {
      testimonial:
        "Going beyond what you know opens up new worlds for you. Find your values, explore your passions, and become more self-aware.",
      image: AIESECer,
      localCommittee: "AIESEC IN JOS",
      name: "Ezimorah Tobenna",
    },
    {
      testimonial:
        "Going beyond what you know opens up new worlds for you. Find your values, explore your passions, and become more self-aware.",
      image: AIESECer,
      localCommittee: "AIESEC IN JOS",
      name: "Ezimorah Tobenna",
    },
    {
      testimonial:
        "Going beyond what you know opens up new worlds for you. Find your values, explore your passions, and become more self-aware.",
      image: AIESECer,
      localCommittee: "AIESEC IN JOS",
      name: "Ezimorah Tobenna",
    },
    {
      testimonial:
        "Going beyond what you know opens up new worlds for you. Find your values, explore your passions, and become more self-aware.",
      image: AIESECer,
      localCommittee: "AIESEC IN JOS",
      name: "Ezimorah Tobenna",
    },
    {
      testimonial:
        "Going beyond what you know opens up new worlds for you. Find your values, explore your passions, and become more self-aware.",
      image: AIESECer,
      localCommittee: "AIESEC IN JOS",
      name: "Ezimorah Tobenna",
    },
    {
      testimonial:
        "Going beyond what you know opens up new worlds for you. Find your values, explore your passions, and become more self-aware.",
      image: AIESECer,
      localCommittee: "AIESEC IN JOS",
      name: "Ezimorah Tobenna",
    },
  ];
  return (
    <PagesContainer
      testimonials={testimonials}
      heroImage={AIESECMemberHeroImage}
      titleText="Become an AIESEC member"
      organizations="Become an AIESEC member"
      subText="Our Partners"
      reasons={reasons}
      notshowOrganization={true}
    />
  );
};

export default Membership;
