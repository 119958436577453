import classes from "./ForOrganizationInternationalPool.module.css";
import worldMap from "../../Assets/Images/worldMap.svg";
import Button from "../../Components/Button/Button";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import ForOrganizationsDrawer from "../ForOrganizationsDrawer/ForOrganizationsDrawer";

const ForOrganizationInternationalPool = () => {
  // Effects
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };


  return (
    <section className={classes.container}>
      <h4 data-aos="fade-up">Access our international talent pool</h4>
      <p data-aos="fade-up">
        We give you access to young talents from over 120+ countries and
        territories around the world
      </p>
      <div className={classes.buttonSection}>
        {/* <a href="mailto:nigeria@aiesec.net"> */}
        <Button onClick={showDrawer}>Get Started</Button>
        {/* </a> */}

        {
          open ? (<ForOrganizationsDrawer open={open} onClose={onClose} />) : ''
        }
      </div>
      <img src={worldMap} alt="World map" data-aos="fade-up" loading="lazy" />
    </section>
  );
};

export default ForOrganizationInternationalPool;
