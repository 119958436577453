import Layout from "../../Components/Layout/Layout";
import AboutUs1948 from "../../Containers/AboutUs1948/AboutUs1948";
import AboutUsEB from "../../Containers/AboutUsEB/AboutUsEB";
import AboutUsFAQ from "../../Containers/AboutUsFAQ/AboutUsFAQ";
import AboutUsHeroSection from "../../Containers/AboutUsHeroSection/AboutUsHeroSection";
import AboutUsLocalCommittees from "../../Containers/AboutUsLocalCommittees/AboutUsLocalCommittees";
// import AboutUsWhatWeDo from "../../Containers/AboutUsWhatWeDo/AboutUsWhatWeDo";
import LandingPageNewsLetterSignUp from "../../Containers/LandingPageNewsLetterSignUp/LandingPageNewsLetterSignUp";

const AboutUs = () => {
  return (
    <Layout>
      <AboutUsHeroSection />
      <AboutUs1948 />
      {/* <AboutUsWhatWeDo /> */}
      <AboutUsEB />
      <AboutUsLocalCommittees />
      <AboutUsFAQ />
      <LandingPageNewsLetterSignUp />
    </Layout>
  );
};

export default AboutUs;
