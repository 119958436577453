import { useNavigate } from "react-router-dom";
import Layout from "../../Components/Layout/Layout";
import classes from "./404.module.css";
import CoffeeIllustration from "../../Assets/Images/404CoffeeIllustration.svg";

const ErrorPage = () => {
  // Navigate
  const navigate = useNavigate();

  return (
    <Layout>
      <div className={classes.container}>
        <div className={classes.textSection}>
          <p>
            Oops! Looks like this web page is taking a <span>coffee break</span>
          </p>
          <p>
            Don’t worry, it will be back soon with some fresh internet goodness!
            In the meantime, feel free to explore other corners of our website.
            Stay tuned!
          </p>
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            Back To Homepage
          </button>
        </div>
        <div className={classes.imageSection}>
          <img
            src={CoffeeIllustration}
            alt="Oops! Looks like this web page is taking a coffee break"
            loading="lazy"
          />
        </div>
      </div>
    </Layout>
  );
};

export default ErrorPage;
