import classes from "./BlogHero.module.css";
import FeaturedBlogHeader from "../../Assets/Images/FeaturedBlogHeader.webp";
import BlogHeader1 from "../../Assets/Images/BlogHeader1.webp";
import BlogHeader2 from "../../Assets/Images/BlogHeader2.webp";
import { Link } from "react-router-dom";
import { scrollToTheTop } from "../../Utilities/scrollToTop";

const BlogHero = () => {
  return (

    <section className={classes.container}>
      <h4>
        <span>
          <span>Broaden</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="12"
            viewBox="0 0 165 12"
            fill="none"
          >
            <path
              d="M1 11C38.5664 11 75.8735 3.59409 113.593 3.31674C129.237 3.20171 144.737 3.10847 160.357 3.31674C164.588 3.37316 164.401 2.5429 160.167 2.46302C152.652 2.32122 145.744 1.02839 138.066 1.41962C123.89 2.14198 109.667 1.62498 95.4759 2.27331C66.5357 3.59544 37.5664 3.10069 8.6833 4.17042"
              stroke="#037EF3"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </span>{" "}
        your horizon <br />
        with AIESEC
      </h4>

      <div className={classes.blogContainer}>
        <div className={classes.featuredBlog}>
          <Link onClick={scrollToTheTop} className={classes.featuredBlogLinkContainer} to="/blog/1">
            <div className={classes.featuredBlogImgContainer}>
              <img className={classes.featuredBlogImg} src={FeaturedBlogHeader} alt="Featured Blog Header" />
            </div>


            <h4 className={classes.featuredBlogTitle}>I Want To Change The World. How About You?</h4>
            <p className={classes.featuredBlogDesc}>I don’t think there has ever been a prouder time to be a young Nigerian living in Nigeria than now, and no, I’m not being sarcastic. For as long as I can remember, the proverb “Youths are the leaders of tomorrow”</p>
            <Link onClick={scrollToTheTop} to ="/blog/1" className={classes.featuredBlogLink} href="https://medium.com/@aiesecng21/i-want-to-change-the-world-how-about-you-12828c7ebae7">
              <span>Read More  </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  opacity="0.7"
                  d="M1.5 10.5L11 1M11 1H4.18868M11 1V7.63208"
                  stroke="#037EF3"
                  stroke-width="1.43396"
                />
              </svg>
            </Link>
          </Link>
        </div>
        <div className={classes.blogCardContainer}>

          <Link onClick={scrollToTheTop} className={classes.featuredBlogLinkContainer} to="/blog/2">

            <div className={classes.blogCard}>

              <div className={classes.blogCardImgContainer}>
                <img className={classes.featuredBlogImg} src={BlogHeader1} alt="Featured Blog Header" />
              </div>
              <div className={classes.blogCardContainer}>

                <h4 className={classes.blogCardTitle}>Dear Amazing Earth Hero</h4>
                <p className={classes.featuredBlogDesc}>In the wide web of human experiences, “firsts” have always held a special allure — a beautiful blend of excitement and trepidation, a certain kind of anticipation and uncertainty. There is an undeniable energy that comes with a “first,” a good sort of anxiety (if that exists).</p>
                <Link onClick={scrollToTheTop} className={classes.featuredBlogLink} to="/blog/2">
                  <span>Read More  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      opacity="0.7"
                      d="M1.5 10.5L11 1M11 1H4.18868M11 1V7.63208"
                      stroke="#037EF3"
                      stroke-width="1.43396"
                    />
                  </svg>
                </Link>
              </div>
            </div>

          </Link>
          <Link onClick={scrollToTheTop} className={classes.featuredBlogLinkContainer} to="/blog/3">

            <div className={classes.blogCard}>
              <div className={classes.blogCardImgContainer}>
                <img className={classes.featuredBlogImg} src={BlogHeader2} alt="Featured Blog Header" />
              </div>
              <div className={classes.blogCardContainer}>

                <h4 className={classes.blogCardTitle}>The Superheroes Among Us</h4>
                <p className={classes.featuredBlogDesc}>When you hear the word “superhero”, what comes to mind? I bet it’s the picture of a man in a blue and red costume with chiselled abs, a cape and an S on the chest. It could even be a woman in an armour-like costume, wicked boots and a rope by her waist.</p>
                <Link onClick={scrollToTheTop} className={classes.featuredBlogLink} to="/blog/3">
                  <span>Read More  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      opacity="0.7"
                      d="M1.5 10.5L11 1M11 1H4.18868M11 1V7.63208"
                      stroke="#037EF3"
                      stroke-width="1.43396"
                    />
                  </svg>
                </Link>
              </div>

            </div>

          </Link>
        </div>
        <div>

        </div>
      </div>

    </section>
  );
};

export default BlogHero;
