import { useEffect } from "react";
// import { useEffect, useRef } from "react";
import Layout from "../Layout/Layout";
import classes from "./PagesContainer.module.css";
import AIESECMember from "../../Assets/Images/AIESECMember.svg";
import FormContainer from "../../Containers/FormContainer/FormContainer";
// import AboutUsLocalCommittees from "../../Containers/AboutUsLocalCommittees/AboutUsLocalCommittees";
import MembershipRole from "../../Containers/MembershipRoles/MembershipRoles";
// import StepArea from "../../Containers/StepArea/StepArea";
import LandingPageTestimonials from "../../Containers/LandingPageTestimonials/LandingPageTestimonials";
import Aos from "aos";
import "aos/dist/aos.css";
// import VideoPlayer from "../VideoPlayer/VideoPlayer";

const PagesContainer = ({
  // testimonials,
  heroImage,
  titleText,
  subText,
  organizations,
  duration,
  notshowOrganization,
  // reasons,
}) => {
  // Refs
  // const scrollableRef = useRef(null),

  // utils
  // const unLimitedTestimonials = [...testimonials],

  //   Effects
  // useEffect(() => {
  //   const scrollableElement = scrollableRef.current,
  //   let currentIndex = 0,

  //   const scrollNextBox = () => {
  //     if (currentIndex < scrollableElement.children.length - 1) {
  //       currentIndex++,
  //       const nextBox = scrollableElement.children[currentIndex],
  //       scrollableElement.scrollTo({
  //         top: 0,
  //         left: nextBox.offsetLeft,
  //         behavior: "smooth",
  //       }),
  //     } else {
  //       currentIndex = 0,
  //       scrollableElement.scrollTo({
  //         top: 0,
  //         left: 0,
  //         behavior: "smooth",
  //       }),
  //     }
  //   },

  //   const scrollInterval = setInterval(scrollNextBox, 5000),

  //   return () => {
  //     clearInterval(scrollInterval),
  //   },
  // }, []),

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <Layout>
      <section className={classes.container}>
        <div className={classes.heroImageSection}>
          <img src={heroImage} alt="AIESEC" loading="lazy" />
        </div>
        <div className={classes.titleSection}>
          <h2>
            <span className={classes.aboutText}>Become an</span> AIESEC Member
          </h2>
        </div>
        <hr style={{ width: "50%", border: "0.2px solid #E1E1E1" }} />
        <div className={classes.aboutContainer}>
          <h6>
            <span className={classes.aboutText}>ABOUT</span> AIESEC
          </h6>
          <p>
            AIESEC is a global platform for young people to develop their
            leadership potential through international internships and volunteer
            opportunities. We facilitate and promote cultural understanding and
            develop socially responsible, proactive agents of change with a view
            of making a positive impact in the world through our International
            Exchange and Membership Program.
          </p>

          <div className={classes.mediaView}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/jRuw-UWrRkc?si=vAinxpG2OnSDU0Jn"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>

            {/* <VideoPlayer
              url="https://www.youtube.com/embed/jRuw-UWrRkc?si=vAinxpG2OnSDU0Jn"
              height="532px"
            /> */}
          </div>
        </div>
        <div>
          <div className={classes.roles}>
            <div className={classes.topImage}>
              <img src={AIESECMember} alt="AIESEC member logo" loading="lazy" />
            </div>
            <div className={classes.rolesText}>
              <h2>
                <span className={classes.aboutText}>Our </span>roles
              </h2>
              <p>
                When you join one of our local AIESEC teams, you are assigned to
                a smaller team in a specific department. Think of it as the
                departments in any organisation or company.
              </p>
            </div>
          </div>
          <MembershipRole />
          <div className={classes.blueDash}></div>
        </div>

        {/* <div className={classes.reasons}>
          {reasons.map((data, i) => {
            return (
              <div key={i} data-aos="fade-up">
                <div></div>
                <h5>{data.title}</h5>
                <p>{data.description}</p>
              </div>
            ),
          })}
        </div> */}

        {/* <div className={classes.testimonials}>
          <h4 data-aos="fade-up">
            Develop your leadership while boosting your career prospects with an
            international internship in your field
          </h4>
          <div className={classes.testimonialSection}>
            <div className={classes.testimonialLeft}></div>
            <div className={classes.testimonialRight}></div>
            <div
              className={classes.testimonialScrollContainer}
              ref={scrollableRef}
            >
              {unLimitedTestimonials.map((data, i) => {
                return (
                  <div className={classes.testimonial} key={i}>
                    <p>{data.testimonial}</p>
                    <div>
                      <img src={data.image} alt="Testimonial" loading="lazy" />
                      <div>
                        <h6>{data.name}</h6>
                        <p>{data.localCommittee.toUpperCase()}</p>
                      </div>
                    </div>
                  </div>
                ),
              })}
            </div>
          </div>
        </div> */}
        {/* <AboutUsLocalCommittees isComponent={true} /> */}
        <LandingPageTestimonials />
        {/* <StepArea /> */}
        <FormContainer />
      </section>
    </Layout>
  );
};

export default PagesContainer;
