import React from 'react'
import classes from "./BlogArticles.module.css";
import BlogHeader1 from "../../Assets/Images/BlogHeader1.webp";
import BlogHeader2 from "../../Assets/Images/BlogHeader2.webp";
import FeaturedBlogHeader from "../../Assets/Images/FeaturedBlogHeader.webp";
import FeaturedBlogHeader1 from "../../Assets/Images/FeaturedBlogHeader1.webp";
import Author from '../../Assets/Images/author1.jpg';
import ShareButton from '../../Components/ShareButton/ShareButton';
import { scrollToTheTop } from "../../Utilities/scrollToTop";
import { Link } from 'react-router-dom';

function BlogArticles() {
  return (
    <section className={classes.container}>
      <div className={classes.blogHeading}>
        <div className={classes.blogHeadingTitle}>
          <span>10th March, 2023</span>
          <h1>I Want To Change The World. How About You?</h1>
          <div className={classes.blogHeadingTitleAuthor}>
            <img src={Author} alt="" />
            <span>By Emmanuel Obi</span>
          </div>

        </div>

        <div className={classes.blogHeadingImg}>
          <img src={FeaturedBlogHeader} alt="" />
        </div>
      </div>
      <div className={classes.blogContent}>

        <p>I don&apos;t think there has ever been a prouder time to be a young Nigerian living in Nigeria than now, and no, I&apos;m not being sarcastic. For as long as I can remember, the proverb “Youths are the leaders of tomorrow” has been met with jeers and laughter whenever it is said out loud. Think about that for a second. Such a powerful and meaningful statement, reduced to ridicule for obvious reasons. It is the same for countless other proverbs that should mean something, and it doesn’t just stop at proverbs.</p>
        <p>It is not ludicrous to say that the average Nigerian carries a defeatist mentality usually born from bitter experiences. However, there is no denying that the events of the past few weeks have reignited confidence and belief, particularly amongst the youth, something that has been absent for a long time. Like countless other young Nigerians, I’ve realized that my voice matters and that I am not insignificant in the grand scheme of things. I am not afraid to say I want to change the world. How about you?</p>
        <img src={BlogHeader1} alt="" />

        <p>Very often, society programs us to think that changing the world or making an impact in our community is a herculean task we are incapable of. I also shared the same mentality until recently when something my mother casually mentioned during a conversation reminded me of a time I inspired change without realizing it. To tell this story, we would have to go back to 2017 when I had just recently completed my A-level program and was back home awaiting the results. My mother, not wanting me to be idle, found a place for me to work in the lab of a private clinic running out of Jakande, Lagos state. I was responsible for inputting the data of patients into an excel sheet, basically record-keeping. Such a basic task bored me, and to pass the time, I established a database for the clinic and made weekly backups.</p>
        <p>Fast forward to 2023, and during one of our Sunday evening conversations, my mother mentions that the family member of a recently deceased patient came to the clinic claiming that they had been providing the wrong medication for the patient resulting in their death. Thanks to the weekly backup tradition I began, they could access years of records absolving the clinic of any blame for said patients’ deaths.</p>
        <p>This got me thinking, how many times have we done something for our community and the institutions within it that, at the time, we thought was insignificant but ended up being important? How often have we done something for our church, mosques and schools that have helped in ways we can’t even fathom? I believe this enforces the fact that at some point, we have all been volunteers, and we have all inspired change and made an impact within our communities, even without our knowledge.</p>
        <p>Now more than ever, I am convinced that this is how we can impact the communities around us and bring about a better Nigeria. These little acts of service are how we can change the world. It isn’t until we perform some extravagant task or make a groundbreaking discovery that we can impact the world around us.</p>
        <img src={FeaturedBlogHeader1} alt="" />
        <p>One thing the events of the last few weeks have made painfully obvious is that we do not understand each other. We live in an extremely diverse country, with over 200 ethnic groups and over 500 recognized languages. We can only move forward in peace and unity through open dialogue and communication, which is only possible if we spend time amongst each other. This is why AIESEC in Nigeria’s National Volunteer programme is an important opportunity for the youth in our country. For two weeks, you get to volunteer on projects that resonate with you in a different state of your choosing.</p>
        <p>Whether you are someone that is passionate about social causes and determined to do something, or you&apos;re just an innate explorer who wants to see other places in Nigeria and experience new cultures, people and environments. National Volunteer is perfect for you. Be the change you wish to see in our country. Don’t miss out on this opportunity. <a href="http://bit.ly/impactnigeria">Sign Up for National Volunteer today.</a></p>
        <div className={classes.shareBlogContent} >

          <span>Share this post</span>
          <ShareButton text="Check out this amazing article! &quot;I Want To Change The World. How About You?&quot;" linktext="http://aiesec.ng/blog/1" />
        </div>
      </div>
      <div className={classes.blogsRelated}>
        <h4>More Articles</h4>
        <div className={classes.blogsRelatedContainer}>
          <Link onClick={scrollToTheTop}  className={classes.featuredBlogLinkContainer} to="/blog/2">
            <div className={classes.blogCard}>
              <div className={classes.blogCardImgContainer}>
                <img className={classes.featuredBlogImg} src={BlogHeader1} alt="Featured Blog Header" />
              </div>
              <div className={classes.blogCardContainer}>
                <h4 className={classes.blogCardTitle}>Dear Amazing Earth Hero</h4>
                <p className={classes.featuredBlogDesc}>In the wide web of human experiences, “firsts” have always held a special allure — a beautiful blend of excitement and trepidation, a certain kind of anticipation and uncertainty. There is an undeniable energy that comes with a “first,” a good sort of anxiety (if that exists).</p>
                <Link onClick={scrollToTheTop}  className={classes.featuredBlogLink} to="/blog/2">
                  <span>Read More  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      opacity="0.7"
                      d="M1.5 10.5L11 1M11 1H4.18868M11 1V7.63208"
                      stroke="#037EF3"
                      stroke-width="1.43396"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </Link>
          <Link onClick={scrollToTheTop}  className={classes.featuredBlogLinkContainer} to="/blog/3">

            <div className={classes.blogCard}>
              <div className={classes.blogCardImgContainer}>
                <img className={classes.featuredBlogImg} src={BlogHeader2} alt="Featured Blog Header" />
              </div>
              <div className={classes.blogCardContainer}>

                <h4 className={classes.blogCardTitle}>The Superheroes Among Us</h4>
                <p className={classes.featuredBlogDesc}>When you hear the word “superhero”, what comes to mind? I bet it’s the picture of a man in a blue and red costume with chiselled abs, a cape and an S on the chest. It could even be a woman in an armour-like costume, wicked boots and a rope by her waist.</p>
                <Link onClick={scrollToTheTop}  className={classes.featuredBlogLink} to="/blog/3">
                  <span>Read More  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      opacity="0.7"
                      d="M1.5 10.5L11 1M11 1H4.18868M11 1V7.63208"
                      stroke="#037EF3"
                      stroke-width="1.43396"
                    />
                  </svg>
                </Link>
              </div>

            </div>

          </Link>
        </div>
      </div>
    </section>
  )
}

export default BlogArticles