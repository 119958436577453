import { useState } from "react";
import classes from "./FAQDropdown.module.css";

const FAQDropdown = ({ header, content }) => {
  // States
  const [displayDropDown, setDisplayDropdown] = useState(false);
  return (
    <div className={classes.container}>
      <div
        className={classes.dropdownHeader}
        onClick={() => {
          setDisplayDropdown((prevState) => {
            return !prevState;
          });
        }}
      >
        <p>{header}</p>
        <div>
          {!displayDropDown ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path d="M15 20L25 20" stroke="black" />
              <path d="M20 25V15" stroke="black" />
              {/* <circle cx="20" cy="20" r="19.5" stroke="black" /> */}
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path d="M15 20L25 20" stroke="black" />
              {/* <circle cx="20" cy="20" r="19.5" stroke="black" /> */}
            </svg>
          )}
        </div>
      </div>
      <div
        className={classes.dropdownContainer}
        style={displayDropDown ? { maxHeight: "400px" } : { maxHeight: "0px" }}
      >
        {content}
      </div>
    </div>
  );
};

export default FAQDropdown;
