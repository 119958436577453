import { useEffect } from "react";
import classes from "./AboutUs1948.module.css";
import Aos from "aos";
import "aos/dist/aos.css";

const AboutUs1948 = () => {
  // Effects
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <section className={classes.container}>
      <h6 data-aos="fade-up">1948</h6>
      <div className={classes.paragraph}>
        <p data-aos="fade-up">
          AIESEC was founded way back in 1948 in 7 countries in Europe. Now,
          more than 65 years later we’re present in over 126 countries and
          territories and are still growing.
        </p>
      </div>
    </section>
  );
};

export default AboutUs1948;
