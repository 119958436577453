import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classes from './Header.module.css';
import AiNLogoBlue from '../../Assets/Images/AiNLogoBlue.png';
import { useRef } from 'react';
import SideNav from '../SideNav/SideNav';
import { scrollToTheTop } from '../../Utilities/scrollToTop';
import Button from '../../Components/Button/Button';
import { activteToggler } from '../../HelperFunctions/activeToggler';
import { useState } from 'react';
import { useEffect } from 'react';

const Header = () => {
  //   Location
  const location = useLocation();
  let element = document && document.getElementById('memberDetails');

  //   states

  //   Refs
  const sideNavRef = useRef(null);

  //   Function to open side nav
  const openSideNav = () => {
    sideNavRef.current.style.width = '100%';
    document.getElementById('sideNavRef').style.width = '100%';
  };

  //   Function to close side nav
  const closeSideNav = () => {
    sideNavRef.current.style.width = '0%';
    document.getElementById('sideNavRef').style.width = '0%';
  };

  const [routes, setRoutes] = useState([
    {
      title: 'Home',
      route: '/',
      dropdownOptions: null,
      relatedRoutes: ['/', ''],
      isActive: false,
    },
    {
      title: 'About',
      route: '/about',
      dropdownOptions: null,
      relatedRoutes: ['about'],
      isActive: false,
    },
    {
      title: 'Become A Member',
      route: '/membership',
      dropdownOptions: null,
      relatedRoutes: ['membership'],
      isActive: false,
    },
    {
      title: 'For Organizations',
      route: '/for-organizations',
      dropdownOptions: null,
      relatedRoutes: ['for-organizations'],
      isActive: false,
    },
    {
      title: 'Blog',
      route: '/blog',
      dropdownOptions: null,
      relatedRoutes: ['blog', 'blog/1', 'blog/2', 'blog/3'],
      isActive: false,
    },

    {
      title: 'Programs',
      route: null,
      dropdownOptions: [
        {
          title: 'Youth Speak Forum',
          route: '/ysf',
          isActive: true,
        },
        {
          title: 'Global Money Week',
          route: '/gmw',
          isActive: true,
        },
        {
          title: 'National Volunteer',
          route: '/nv',
          isActive: true,
        },
        {
          title: 'Leadership Summit',
          route: '/LeadershipSummit',
          isActive: true,
        },
      ],
      relatedRoutes: [
        '/nv',
        '/ysf',
        '/gmw',
        '/LeadershipSummit',
      ],
      isActive: false,
    },
  ]);

  // Refs
  const dropdownRef = useRef(null);

  // Effects
  useEffect(() => {
    const removeDropdownHandler = (e) => {
      if (dropdownRef && !dropdownRef?.current?.contains(e.target)) {
        const routesCopy = routes.map((data) => {
          return { ...data, isActive: false };
        });

        setRoutes(routesCopy);
      }
    };
    document.addEventListener('mousedown', removeDropdownHandler);

    return () => {
      document.removeEventListener('mousedown', removeDropdownHandler);
    };
  });

  return (
    <>
      <div className={classes.container}>
        <div className={classes.logoAndSearch}>
          <hr />
          <Link to="/">
            <img
              src={AiNLogoBlue}
              alt="AIESEC in Nigeria Logo"
              loading="lazy"
            />
          </Link>
        </div>

        <div className={classes.routesSection}>
          {routes.map((route, i) => {
            if (route.dropdownOptions) {
              return (
                <div
                  key={i}
                  onClick={() => {
                    activteToggler(i, routes, setRoutes);
                  }}
                >
                  <span>{route.title}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      d="M12.0063 14.6953L17.0063 9.69531H7.00635L12.0063 14.6953Z"
                      fill="#2E3A59"
                    />
                  </svg>

                  {route.isActive && (
                    <div className={classes.dropdown} ref={dropdownRef}>
                      {route.dropdownOptions.map((subRoute, j) => {
                        return (
                          <Link
                            to={subRoute.route}
                            key={j}
                            className={
                              !subRoute.isActive
                                ? classes.inActive
                                : classes.active
                            }
                          >
                            {subRoute.title}
                          </Link>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            } else
              return (
                <Link
                  to={route.route}
                  key={i}
                  onClick={scrollToTheTop}
                  target={route.route.includes('http') && '_blank'}
                >
                  <span>{route.title}</span>
                  {route?.relatedRoutes.includes(
                    location.pathname.slice(1)
                  ) && (
                    <span className={classes.activeRouteIndicator}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        // width="72"
                        height="13"
                        viewBox="0 0 72 13"
                        fill="none"
                      >
                        <path
                          d="M7.47767 8.24647C23.1049 6.1167 70.1407 1.28052 54.5983 3.96024C43.5324 5.86814 32.1767 6.19474 21.0648 8.02235C15.3574 8.96106 8.72126 11.1112 2.88328 10.7678C-1.46925 10.5118 11.4544 9.1475 15.77 8.52662C33.8187 5.92999 51.8894 4.13235 70.0064 2.19531"
                          stroke="#037EF3"
                          strokeWidth="3"
                          strokeLinecap="round"
                        />
                      </svg>
                    </span>
                  )}
                </Link>
              );
          })}
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="19"
          viewBox="0 0 26 19"
          fill="none"
          onClick={openSideNav}
          className={classes.sideNavIcon}
        >
          <path
            d="M1 1H25"
            stroke="#037EF3"
            strokeWidth="1.41176"
            strokeLinecap="round"
          />
          <path
            d="M6.64709 17.9412L25 17.9412"
            stroke="#037EF3"
            strokeWidth="1.41176"
            strokeLinecap="round"
          />
          <path
            d="M13.7059 9.4707L25.0001 9.4707"
            stroke="#037EF3"
            strokeWidth="1.41176"
            strokeLinecap="round"
          />
        </svg>
        <div className={classes.sideNav} id="sideNavRef">
          <SideNav
            closeSideNav={closeSideNav}
            routes={routes}
            setRoutes={setRoutes}
          />
        </div>
      </div>
      <div className={classes.responsiveContainer}>
        <div className={classes.logoAndSearch}>
          <hr />
          <Link to="/">
            <img
              src={AiNLogoBlue}
              alt="AIESEC in Nigeria Logo"
              loading="lazy"
            />
          </Link>
        </div>
        {location.pathname === '/membership' ? (
          <div className={classes.memberButton}>
            <Button
              onClick={() =>
                element &&
                element.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'nearest',
                })
              }
            >
              Become a member
            </Button>
          </div>
        ) : (
          <>
            <div className={classes.routesSection}>
              {routes.slice(0, 3).map((route, i) => {
                if (route.dropdownOptions) {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        activteToggler(i, routes, setRoutes);
                      }}
                    >
                      <span>{route.title}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <path
                          d="M12.0063 14.6953L17.0063 9.69531H7.00635L12.0063 14.6953Z"
                          fill="#2E3A59"
                        />
                      </svg>
                    </div>
                  );
                } else
                  return (
                    <>
                      <Link to={route.route} key={i}>
                        <span>{route.title}</span>
                        {route?.relatedRoutes.includes(
                          location.pathname.slice(1)
                        ) && (
                          <span className={classes.activeRouteIndicator}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="72"
                              height="13"
                              viewBox="0 0 72 13"
                              fill="none"
                            >
                              <path
                                d="M7.47767 8.24647C23.1049 6.1167 70.1407 1.28052 54.5983 3.96024C43.5324 5.86814 32.1767 6.19474 21.0648 8.02235C15.3574 8.96106 8.72126 11.1112 2.88328 10.7678C-1.46925 10.5118 11.4544 9.1475 15.77 8.52662C33.8187 5.92999 51.8894 4.13235 70.0064 2.19531"
                                stroke="#037EF3"
                                strokeWidth="3"
                                strokeLinecap="round"
                              />
                            </svg>
                          </span>
                        )}
                      </Link>
                    </>
                  );
              })}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="19"
              viewBox="0 0 26 19"
              fill="none"
              onClick={openSideNav}
            >
              <path
                d="M1 1H25"
                stroke="#037EF3"
                strokeWidth="1.41176"
                strokeLinecap="round"
              />
              <path
                d="M6.64709 17.9412L25 17.9412"
                stroke="#037EF3"
                strokeWidth="1.41176"
                strokeLinecap="round"
              />
              <path
                d="M13.7059 9.4707L25.0001 9.4707"
                stroke="#037EF3"
                strokeWidth="1.41176"
                strokeLinecap="round"
              />
            </svg>
          </>
        )}

        <div className={classes.sideNav} ref={sideNavRef}>
          <SideNav
            closeSideNav={closeSideNav}
            routes={routes.slice(3)}
            setRoutes={setRoutes}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
