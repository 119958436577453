import Layout from '../../Components/Layout/Layout';
import BlogArticleTwo from '../../Containers/BlogArticleTwo/BlogArticleTwo';
const BlogTwo = () => {
    return (
        <Layout>
            <BlogArticleTwo />
        </Layout>
    );
}

export default BlogTwo;