import Layout from '../../Components/Layout/Layout';
import NvProjectsPageProjects from '../../Containers/NvProjectsPageProjects/NvProjectsPageProjects';
import NvProjectsPageWriteUp from '../../Containers/NvProjectsPageWriteUp/NvProjectsPageWriteUp';

const NvProjects = () => {
  return (
    <Layout>
      <NvProjectsPageWriteUp />
      <NvProjectsPageProjects />
    </Layout>
  );
};

export default NvProjects;
