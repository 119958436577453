import classes from './GmwHomePageHero.module.css';
import gmwlogo from '../../Assets/Images/gmwlogowhite.svg';
import frame from '../../Assets/Images/blockcolor.svg';

const GmwHomePageHero = () => {
  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <img src={gmwlogo} alt="the gmw logo in white color" />
      </div>
      <div className={classes.heroText}>
        <h3>
          Protect your money, secure your <span>Future</span>
        </h3>
        <p>
          Your personal finance knowledge and decisions should go a step higher.
          2024’s Global Money Week’s theme was all about young people taking
          charge of their money. See how AIESEC in Nigeria helped with that all
          over the country.
        </p>
      </div>
      <div className={classes.colorContainer}>
        <img src={frame} alt="a block of different colors" />
      </div>
    </div>
  );
};

export default GmwHomePageHero;
