import { useEffect } from "react";
import FAQDropdown from "../../Components/FAQDropdown/FAQDropdown";
import classes from "./AboutUsFAQ.module.css";
import Aos from "aos";
import "aos/dist/aos.css";

const AboutUsFAQ = () => {
  const FAQs = [
    {
      header: "What’s AIESEC?",
      content:
        "Founded in 1948, AIESEC is a global non-governmental and not-for-profit youth-led organisation created to help young people with leadership development and potential through cross-cultural exchanges as volunteers and interns. With a presence in countries worldwide, AIESEC was created after World War II to promote peace and fulfilment of mankind among young people in different countries.",
    },
    {
      header: "How can I join AIESEC?",
      content:
        "AIESEC is currently present in 13 states in Nigeria. To join AIESEC, you have to sign up with the state close to you. AIESEC membership recruitment opens up just once a year, and the recruitment process is tailored to simulate the AIESEC experience.",
    },
    {
      header: "What does AIESEC stand for?",
      content:
        "AIESEC (pronounced as eye-sek) was formerly an acronym for “ Association Internationale des Étudiants en Sciences Économiques et Commerciales.”. However, it is no longer used as an acronym but simply as the name of the organisation.",
    },
    {
      header: "Is AIESEC in my State?",
      content:
        "AIESEC is currently present in the following states in Nigeria: Lagos, Ondo, Ogun, Plateau, Enugu, Edo, Cross-River, Benue, Kwara, Abuja, Oyo, Osun, and Rivers States.",
    },
  ];

  // Effects
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <section className={classes.container}>
      <h4 data-aos="fade-up">FAQS</h4>
      <div className={classes.dropdownSection}>
        {FAQs.map((data, i) => {
          return (
            <div key={i} data-aos="fade-down">
              <FAQDropdown
                header={`${i + 1 < 9 ? `0${i + 1}` : i + 1}.  ${data.header}`}
                content={data.content}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default AboutUsFAQ;
