import { useEffect } from "react";
import classes from "./AboutUsHeroSection.module.css";
import aboutHero1 from "../../Assets/Images/aboutHero1.png";
import aboutHero2 from "../../Assets/Images/aboutHero2.png";
import aboutHero3 from "../../Assets/Images/aboutHero3.png";
import aboutHero4 from "../../Assets/Images/aboutHero4.png";
import aboutHero5 from "../../Assets/Images/aboutHero5.png";
import Aos from "aos";
import "aos/dist/aos.css";
import useEmblaCarousel from 'embla-carousel-react'
import AutoPlay from "embla-carousel-autoplay"; 

const AboutUsHeroSection = () => {
  // Utils
  const images = [aboutHero1, aboutHero2, aboutHero3, aboutHero4, aboutHero5];
  const [emblaRef] = useEmblaCarousel({ slidesToScroll: 'auto', containScroll: 'trimSnaps', loop: 'true', dragFree: 'true' }, [AutoPlay({stopOnInteraction:false, delay: 5000, })]);

  // Effects
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <section className={classes.container}>
      <h4 className={classes.header}>
        A{" "}
        <span>
          <span>youth</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            // width="165"
            height="12"
            viewBox="0 0 165 12"
            fill="none"
          >
            <path
              d="M1 11C38.5664 11 75.8735 3.59409 113.593 3.31674C129.237 3.20171 144.737 3.10847 160.357 3.31674C164.588 3.37316 164.401 2.5429 160.167 2.46302C152.652 2.32122 145.744 1.02839 138.066 1.41962C123.89 2.14198 109.667 1.62498 95.4759 2.27331C66.5357 3.59544 37.5664 3.10069 8.6833 4.17042"
              stroke="#037EF3"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </span>{" "}
        organisation on
        <br /> a mission
      </h4>
      <div className={classes.imageSetion} data-aos="fade-up-left">
        {images.map((image, i) => {
          return (
            <img
              key={i}
              src={image}
              alt={`AIESEC In Nigeria ${i}`}
              loading="lazy"
            />
          );
        })}
      </div>
      <div className={classes.imageSectionMobile}>
        <div className={classes.imageSectionMobileCarousel}>
          <div className={classes.imageSectionMobileCarousel__viewport} ref={emblaRef}>
            <div className={classes.imageSectionMobileCarousel__container}>
              {images.map((image, i) => {
                return (
                  <div className={classes.imageSectionMobileCarousel__slide}>

                    <div key={i} className={classes.partner} >
                      <img key={i} alt={`AIESEC In Nigeria ${i}`} src={image} loading="lazy" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsHeroSection;
