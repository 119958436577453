import Layout from '../../Components/Layout/Layout';
import BlogArticles from '../../Containers/BlogArticles/BlogArticles';


const BlogOne = () => {
    return (
        <Layout>
            <BlogArticles />
        </Layout>
    );
}

export default BlogOne;