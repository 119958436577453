import Footer from '../../Containers/Footer/Footer';
import Header from '../../Containers/Header/Header';
import SocialSideNav from '../../Containers/SocialSideNav/SocialSideNav';
import classes from './Layout.module.css';

const Layout = ({ children, notShowFooter }) => {
  return (
    <div className={classes.container}>
      <div className={classes.leftSection}>
        <div className={classes.leftSectionInner}>
          <SocialSideNav />
        </div>
      </div>
      <div className={classes.bodyContainer}>
        <div className={classes.header}>
          <Header />
        </div>
        <div className={classes.body}>{children}</div>
        {!notShowFooter && (
          <div className={classes.footer}>
            <Footer />
          </div>
        )}
      </div>
    </div>
  );
};

export default Layout;
