import classes from "./ForOrganizationsCreateOppurtunities.module.css";
import globalTalent from "../../Assets/Images/globalTalent.svg";
import globalVolunteer from "../../Assets/Images/gv.svg";
import globalTeacher from "../../Assets/Images/gt.png";
import ForOrganizationsDrawer from "../ForOrganizationsDrawer/ForOrganizationsDrawer";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";

const ForOrganizationsCreateOppurtunities = () => {
  // Utils
  const oppurtunities = [
    {
      image: globalTalent,
      title: "If you are a company",
      description:
        "Our Global Talent program helps companies fulfill their Human Resources needs with skilled youth ready to work in their field of expertise.",
      ctaLink: "/",
    },
    {
      image: globalTeacher,
      title: "If you are an educational institution or organzation",
      description:
        "Elevate your team through our Global Teacher Program, where skilled young talents are eager to share their expertise and foster growth.",
      ctaLink: "/",
    },
    {
      image: globalVolunteer,
      title: "If you’re looking for volunteers for your NGO or project",
      description:
        "Our Global Talent program helps companies fulfill their Human Resources needs with skilled youth ready to work in their field of expertise.",
      ctaLink: "/",
    },
  ];

  // Effects
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };


  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <h4 data-aos="fade-up">
          Create opportunities and hire talents from across the world
        </h4>
        <p data-aos="fade-up">
          We have a variety of programs that align with your organization type.
        </p>
      </div>

      <div className={classes.aboutUserType}>
        {oppurtunities.map((data, i) => {
          return (
            <div key={i} data-aos="fade-up">
              <img src={data.image} alt={data.title} />
              <h4>{data.title}</h4>
              <p>{data.description}</p>
              <div>
                {/* <Link to={data.ctaLink}>Learn More</Link> */}
                <button onClick={showDrawer}>Learn More </button>


                {
                  open ? (<ForOrganizationsDrawer open={open} onClose={onClose} />) : ''
                }

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    opacity="0.7"
                    d="M1.5 10.5L11 1M11 1H4.18868M11 1V7.63208"
                    stroke="#037EF3"
                    stroke-width="1.43396"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="126"
                  height="9"
                  viewBox="0 0 126 9"
                  fill="none"
                >
                  <path
                    d="M1.34009 7.94615C29.8501 7.94615 58.1635 2.32561 86.79 2.11512C98.6623 2.02783 110.426 1.95706 122.28 2.11512C125.491 2.15794 125.349 1.52784 122.136 1.46722C116.433 1.3596 111.19 0.378442 105.363 0.675354C94.6043 1.22357 83.8105 0.831205 73.0402 1.32324C51.0768 2.32664 29.0912 1.95116 7.17113 2.76301"
                    stroke="#037EF3"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ForOrganizationsCreateOppurtunities;
